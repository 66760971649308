export interface ICredentials {
  token: string
  user: User
}

export enum UserType {
  admin = 'admin',
  user = 'user'
}

export type UserTypes =
  | 'admin'
  | 'user'

export interface User {
  fullName: string
  id: string
  email: string
  type: UserTypes
}

export interface CarAttributes {
  'Rodado': string | null
  'Tapizado': string | null
  'Aire acondicionado': boolean,
  'Calefaccion auxiliar': boolean,
  'Techo panoramico': boolean,
  'Velocidad crucero': boolean,
  'Bluetooth': boolean,
  'GPS': boolean,
  'Sensores de estacionamiento': boolean,
  'ABS': boolean,
  'Airbags': boolean,
  'Cierre centralizado': boolean,
  'Cobertor de caja': boolean,
  'Direccion asistida': boolean,
  'Inmovilizador': boolean,
  'Camara trasera': boolean,
  'Control de estabilidad': boolean,
  'Baranda antivuelco': boolean,
  'Caja de herramientas': boolean,
  'Estribos': boolean,
  'Espejo lateral electrico': boolean,
  'Llantas de aleacion': boolean,
  'Paquete deportivo': boolean,
  'Suspension deportiva': boolean,
}

export interface Image {
  id: string
  key: string
  order: number
  url: string
  date: Date
}

export interface AgencyCar {
  id: string
  make: string
  model: string
  color: string
  year: number
  price: number
  currency: string
  condition: string
  fuel?: string
  transmision?: string
  attributes: CarAttributes
  km?: number
  images: Image[]
  videoUrl?: string
}

export const defaultCarAttributes: CarAttributes = {
  Rodado: '',
  Tapizado: '',
  'Aire acondicionado': false,
  'Calefaccion auxiliar': false,
  'Techo panoramico': false,
  'Velocidad crucero': false,
  Bluetooth: false,
  GPS: false,
  'Sensores de estacionamiento': false,
  ABS: false,
  Airbags: false,
  'Cierre centralizado': false,
  'Cobertor de caja': false,
  'Direccion asistida': false,
  Inmovilizador: false,
  'Camara trasera': false,
  'Control de estabilidad': false,
  'Baranda antivuelco': false,
  'Caja de herramientas': false,
  Estribos: false,
  'Espejo lateral electrico': false,
  'Llantas de aleacion': false,
  'Paquete deportivo': false,
  'Suspension deportiva': false,
}

export interface AgencyCarImagesMap {
  'image-1'?: Image,
  'image-2'?: Image,
  'image-3'?: Image,
  'image-4'?: Image,
  'image-5'?: Image,
}
export type GenderTypes = 'male' | 'female'

export const genderLabelMap = {
  male: 'Masculino',
  female: 'Femenino'
}

export interface Client {
  id: string
  fullName: string;
  fullAddress?: string;
  phoneNumber?: string;
  email?: string;
  gender?: GenderTypes;
}
export interface AgencyProcedure {
  id: string
  description: string
  cost: number
  date: Date
  clientId: string
  client?: Client
}

export type ExpenseType = 'Apartment' | 'Car'

export interface Expense {
  description: string
  date: Date
  cost: number
  apartment?: Apartment
  car?: Car
  type: ExpenseType
}

export type ExpenseDescriptionAverageCost = {
  description: string,
  averageCost: number
}

export interface MonthlyExpenseCostAugmentRate {
  month: number,
  year: number,
  augmentRate: number,
  apartmentAddress: string,
  carModel: string,
  expenseDescription: string
}

export interface Apartment {
  id: string
  city: string
  fullAddress: string
  expenses: Expense[]
}

export interface ApartmentRent {
  id: string
  cost: number
  startedAt: Date
  endedAt: Date
  fullAddress: string
  client: Client
  apartment: Apartment
}

export interface Car {
  id: string
  make: string
  model: string
  color: string
  yearOfManufacturing: number
}

export interface CarRent {
  id: string
  cost: number
  startedAt: Date
  endedAt: Date
  client: Client
  car: Car
}

export interface UpdatePassword {
  currentPassword: string,
  newPassword: string,
  repeatNewPassword: string
}

export enum RentEntity {
  Car = 'car',
  Apartment = 'apartment'
}

export interface GenderPercentage {
  gender: string,
  percentage: number

}

export interface Chart {
  x: string
  y: number
  tooltip?: string
}

export interface TopRentedApartment {
  apartmentId: string
  address: string
  count: string
}

export interface TopRentedCar {
  carId: string
  color: string
  make: string
  model: string
  count: string
}

export interface PopularRentalMonth {
  month: number
  frequency: number
}

export interface AverageRentByCity {
  city: string,
  averageRent: string
}
