/* eslint-disable import/no-named-as-default-member */
import { useState, useEffect, useMemo } from 'react'
import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PersonIcon from '@mui/icons-material/Person'
import { useHistory } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { debounce } from 'lodash'
import { ELinks } from '../../../routes/links'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { TypographyIcon } from '../../../shared/material-rounded/TypographyIcon'
import UserService from '../../../../services/user.service'
import { RQueryKeys, getUserQueryKey } from '../../../../types/react-query'
import { User } from '../../../../types/types'

export const Users = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()

  const usersQuery = useQuery([RQueryKeys.Users, page], async () => {
    const res = await UserService.list(search, page)
    return res.data
  })

  const debounceSearchRefetch = useMemo(() => debounce(usersQuery.refetch, 500), [])

  useEffect(() => {
    debounceSearchRefetch()
  }, [search])

  const viewUser = (user: User) => {
    queryClient.setQueryData(getUserQueryKey(user.id), user)
    history.push({
      pathname: `${ELinks.user}/${user.id}`,
      state: user
    })
  }

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      maxWidth='xs'
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Stack
        width='90%'
        height={'100%'}
        gap={2}
        alignItems={'center'}
      >
        <Button
          startIcon={<Add />}
          variant='contained'
          sx={{ width: '60%' }}
          onClick={() => history.push(ELinks.userForm)}
        >
          Agregar Usuario
        </Button>

        <RoundedTextField
          label='Buscar usuario'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {
          (usersQuery.data?.rows || []).map((users) => (
            <RoundedCard
              key={users.id}
              sx={{
                width: '100%',
                flexShrink: 0,
              }}
              onClick={() => viewUser(users)}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '1.3rem'
              }}>
                <Stack gap={1}>
                  <TypographyIcon startIcon={PersonIcon}>{users.fullName}</TypographyIcon>
                  <TypographyIcon startIcon={EmailIcon}>{users.email}</TypographyIcon>
                </Stack>
              </Box>
            </RoundedCard>
          ))
        }
        {
          usersQuery.data
          && <Pagination
            count={usersQuery.data?.metadata.totalPages}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        }
      </Stack>
    </Grid >
  )
}
