import { Route, Switch } from 'react-router-dom'
import { AgencyCarView } from './AgencyCar'
import { AgencyCarForm } from './AgencyCar/Form/Form'
import { Layout } from '../../layouts/Layout'
import { ELinks } from '../../routes/links'
import { AgencyCar } from './AgencyCar/AgencyCar'
import { AddClient } from './Clients/Form/AddClients'
import { AgencyProcedures } from './AgencyProcedure/AgencyProcedure'
import { AgencyProcedureForm } from './AgencyProcedure/Form/Form'
import { AgencyProcedureView } from './AgencyProcedure'
import { ClientsView } from './Clients'
import { Clients } from './Clients/ClientView'
import { ProfileView } from './Profile/ProfileView'
import { CarsView } from './Car'
import { Car } from './Car/Car'
import { CarForm } from './Car/Form'
import { ApartmentsView } from './Apartment'
import { Apartment } from './Apartment/Apartment'
import { ApartmentForm } from './Apartment/Form'
import { ApartmentsRentsView } from './ApartmentRent'
import { ApartmentRent } from './ApartmentRent/ApartmentRent'
import { ApartmentRentForm } from './ApartmentRent/Form'
import { CarRent } from './CarRent/CarRent'
import { CarRentsView } from './CarRent'
import { CarRentForm } from './CarRent/Form'
import { Users } from './users/Users'
import { UsersView } from './users/UserView'
import { UserForm } from './users/form/UserForm'
import { DashboardView } from './Dashboard'

const Routes = () => (
  <Layout>
    <Switch>
      <Route
        component={DashboardView}
        exact
        path={ELinks.dashboard}
      />
      <Route
        component={AgencyCarView}
        exact
        path={ELinks.agencyCars}
      />
      <Route
        component={AgencyCarForm}
        exact
        path={ELinks.agencyCarForm}
      />
      <Route
        component={AgencyCar}
        exact
        path={`${ELinks.agencyCar}/:id`}
      />
      <Route
        component={AgencyProcedureView}
        exact
        path={ELinks.agencyProcedureView}
      />
      <Route
        component={AgencyProcedureForm}
        exact
        path={ELinks.agencyProcedureForm}
      />
      <Route
        component={AgencyProcedures}
        exact
        path={`${ELinks.agencyProcedures}/:id`}
      />
      <Route
        component={AddClient}
        exact
        path={ELinks.clientForm}
      />
      <Route
        component={ClientsView}
        exact
        path={ELinks.clients}
      />
      <Route
        component={Clients}
        exact
        path={`${ELinks.client}/:id`}
      />
      <Route
        component={ProfileView}
        exact
        path={`${ELinks.changePassword}`}
      />
      <Route
        component={ApartmentsView}
        exact
        path={ELinks.apartments}
      />
      <Route
        component={Apartment}
        exact
        path={`${ELinks.apartments}/:id`}
      />
      <Route
        component={ApartmentForm}
        exact
        path={`${ELinks.apartmentForm}`}
      />
      <Route
        component={CarsView}
        exact
        path={ELinks.cars}
      />
      <Route
        component={Car}
        exact
        path={`${ELinks.cars}/:id`}
      />
      <Route
        component={CarForm}
        exact
        path={`${ELinks.carForm}`}
      />
      <Route
        component={ApartmentsRentsView}
        exact
        path={ELinks.apartmentRents}
      />
      <Route
        component={ApartmentRent}
        exact
        path={`${ELinks.apartmentRent}/:id`}
      />
      <Route
        component={ApartmentRentForm}
        exact
        path={ELinks.apartmentRentForm}
      />
      <Route
        component={CarRentsView}
        exact
        path={ELinks.carRents}
      />
      <Route
        component={CarRent}
        exact
        path={`${ELinks.carRent}/:id`}
      />
      <Route
        component={CarRentForm}
        exact
        path={ELinks.carRentForm}
      />
      <Route
        component={Users}
        exact
        path={ELinks.users}
      />
      <Route
        component={UserForm}
        exact
        path={ELinks.userForm}
      />
      <Route
        component={UsersView}
        exact
        path={`${ELinks.user}/:id`}
      />
      <Route
        component={Users}
        exact
        path={ELinks.users}
      />
      <Route
        component={UserForm}
        exact
        path={ELinks.userForm}
      />
      <Route
        component={UsersView}
        exact
        path={`${ELinks.user}/:id`}
      />
    </Switch>
  </Layout>
)

export default Routes
