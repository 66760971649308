import API, { AxiosConfig, tryGetAPIHeaders } from '../../config/axios.config'
import { Paginated, Res } from '../../types/response.types'
import { ApartmentRent } from '../../types/types'
import { CreateApartmentRentDto, UpdateApartmentRentDto } from '../../validations/apartment-rent.dto'

const adminResourcePath = '/apartment-rents'
type Resource = ApartmentRent

const create = async (dto: CreateApartmentRentDto): Promise<Resource> => {
  const res = await API.post<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const update = async (dto: UpdateApartmentRentDto): Promise<Resource> => {
  const res = await API.put<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const getOne = async (id: string): Promise<Res<Resource>> => {
  const res = await API.get<Res<Resource>>(`${adminResourcePath}/${id}`, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${adminResourcePath}/${id}`, AxiosConfig())
}

const list = async (
  page: number,
  city: string,
  startedAt: Date | null,
  endedAt: Date | null
): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(adminResourcePath, {
    params: {
      page,
      city,
      startedAt,
      endedAt
    },
    headers: tryGetAPIHeaders(),
  })
  return res.data
}

const listByApartmentId = async (
  id: string,
  page: number,
  city: string,
  startedAt: Date | null,
  endedAt: Date | null
): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(`${adminResourcePath}/apartment/${id}`, {
    params: {
      page,
      city,
      startedAt,
      endedAt
    },
    headers: tryGetAPIHeaders(),
  })
  return res.data
}

const ApartmentRentService = {
  create,
  update,
  getOne,
  deleteOne,
  list,
  listByApartmentId
}

export default ApartmentRentService
