import { Box, Container, CssBaseline } from '@mui/material'
import { useLocation } from 'react-router-dom'
import Navbar from './Navbar'

interface Props {
  children: any
}

export const Layout = (props: Props) => {
  const location = useLocation()
  const isDashboard = location.pathname.includes('dashboard')

  return (
  <Box>
    <CssBaseline />
    <Navbar />
      <Container maxWidth={isDashboard ? 'lg' : 'sm'}>
      <Box marginTop={'5rem'}>
        {props.children}
      </Box>
    </Container>
  </Box>
  )
}
