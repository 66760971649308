import API, { AxiosConfig, tryGetAPIHeaders } from '../../config/axios.config'
import { Paginated, Res } from '../../types/response.types'
import { Car } from '../../types/types'
import { CreateCarDto, UpdateCarDto } from '../../validations/car.dto'

const adminResourcePath = '/cars'
type Resource = Car

const create = async (dto: CreateCarDto): Promise<Resource> => {
  const res = await API.post<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const update = async (dto: UpdateCarDto): Promise<Resource> => {
  const res = await API.put<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const getOne = async (id: string): Promise<Res<Resource>> => {
  const res = await API.get<Res<Resource>>(`${adminResourcePath}/${id}`, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${adminResourcePath}/${id}`, AxiosConfig())
}

const list = async (
  search: string,
  page: number
): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>('/cars', {
    params: {
      search,
      page
    },
    headers: tryGetAPIHeaders(),
  })
  return res.data
}

const CarService = {
  create,
  update,
  getOne,
  deleteOne,
  list
}

export default CarService
