import React, { useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextFieldProps,
  Typography,
  debounce,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { ArrowBack } from '@mui/icons-material'
import {
  RQueryKeys,
  getApartmentRentQueryKey,
} from '../../../../types/react-query'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import ApartmentRentService from '../../../../services/basics/apartment-rent.service'
import { ELinks } from '../../../routes/links'
import { Apartment, ApartmentRent } from '../../../../types/types'
import { ApartmentRentCard } from '../../../shared/Apartment/ApartmentRentCard'
import ApartmentService from '../../../../services/basics/apartment.service'

interface DateRange {
  startedAt: Date | null
  endedAt: Date | null
}

interface Location {
  mode: string
  data?: Apartment
}

export const ApartmentsRentsView = () => {
  const history = useHistory()
  const location = useLocation<Location>()
  const apartment = location.state?.data
  const [city, setCity] = useState('')
  const [searchApartment, setSearchApartment] = useState('')
  const [page, setPage] = useState(1)
  const [dateRange, setDateRange] = useState<DateRange>({
    startedAt: null,
    endedAt: null,
  })
  const [selectedApartment, setSelectedApartment] = useState<Apartment | null>(
    apartment || null
  )

  const queryClient = useQueryClient()

  const apartmentRentsQuery = useQuery(
    [RQueryKeys.ApartmentRents, page, selectedApartment],
    () => {
      if (selectedApartment) {
        return ApartmentRentService.listByApartmentId(
          selectedApartment.id,
          page,
          city,
          dateRange.startedAt,
          dateRange.endedAt
        )
      }
      return ApartmentRentService.list(
        page,
        city,
        dateRange.startedAt,
        dateRange.endedAt
      )
    }
  )

  const apartmentsQuery = useQuery(
    [RQueryKeys.Apartments, selectedApartment],
    () => ApartmentService.list(searchApartment, 1)
  )

  const refetch = useMemo(() => debounce(apartmentRentsQuery.refetch, 500), [])

  useEffect(() => {
    // this is causing refetching when coming back from apartment rent view, even if the data is not old
    refetch()
  }, [city, dateRange])

  const goView = (item: ApartmentRent) => {
    queryClient.setQueryData(getApartmentRentQueryKey(item.id), item)
    history.push({
      pathname: `${ELinks.apartmentRent}/${item.id}`,
      state: item,
    })
  }

  const goBack = () => {
    if (apartment) {
      history.push(`${ELinks.apartments}/${apartment?.id}`)
    } else {
      history.push(ELinks.apartments)
    }
  }

  return (
    <Grid
      style={{ width: '100%' }}
      container
      alignItems='center'
      justifyContent='center'
      flexDirection={'column'}
    >
      <Stack sx={{ width: '90%', gap: 2 }}>
        <Box display='flex' alignItems='center'>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant='h5' component='h1'>
            Alquileres de apartamentos
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}
        >
          <Stack gap={2}>
            <RoundedTextField
              fullWidth
              placeholder='Ciudad'
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <Autocomplete
              loading={apartmentsQuery.isLoading}
              options={apartmentsQuery.data?.data.rows || []}
              getOptionLabel={(option) => option.fullAddress}
              isOptionEqualToValue={(option: Apartment) => option.id === selectedApartment?.id
              }
              value={selectedApartment}
              onChange={(e, value) => setSelectedApartment(value)}
              onInputChange={(_, value) => setSearchApartment(value)}
              renderInput={(params: TextFieldProps) => (
                <RoundedTextField
                  {...params}
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  size='small'
                  placeholder='Seleccionar apartamento'
                />
              )}

            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction='row' gap={3}>
                <DatePicker
                  onChange={(newValue: Date | null) => {
                    setDateRange((prev) => ({ ...prev, startedAt: newValue }))
                  }}
                  value={dateRange.startedAt}
                  inputFormat='dd/MM/yyyy'
                  label='Desde'
                  renderInput={(params: TextFieldProps) => (
                    <RoundedTextField
                      {...params}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Desde',
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                />
                <DatePicker
                  onChange={(newValue: Date | null) => {
                    setDateRange((prev) => ({ ...prev, endedAt: newValue }))
                  }}
                  value={dateRange.endedAt}
                  inputFormat='dd/MM/yyyy'
                  label='Hasta'
                  renderInput={(params: TextFieldProps) => (
                    <RoundedTextField
                      {...params}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Hasta',
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
          {apartmentRentsQuery.isFetching && <CircularProgress />}
          {!apartmentRentsQuery.isFetching
            && (apartmentRentsQuery.data?.data?.rows || []).map((item) => (
              <ApartmentRentCard
                key={item.id}
                fullAddress={item.apartment?.fullAddress}
                city={item.apartment.city}
                startedAt={item.startedAt}
                endedAt={item.endedAt}
                cost={item.cost}
                onClick={() => goView(item)}
              />
            ))}
          {apartmentRentsQuery.data?.data?.rows?.length === 0 && (
            <Typography variant='h6'>No se encontraron alquileres</Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            count={apartmentRentsQuery.data?.data.metadata.totalPages || 0}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
      </Stack>
    </Grid>
  )
}
