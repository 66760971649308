import API, { AxiosConfig, tryGetAPIHeaders } from '../../config/axios.config'
import { Paginated, Res } from '../../types/response.types'
import { CarRent } from '../../types/types'
import { CreateCarRentDto, UpdateCarRentDto } from '../../validations/car-rent.dto'

const adminResourcePath = '/car-rents'
type Resource = CarRent

const create = async (dto: CreateCarRentDto): Promise<Resource> => {
  const res = await API.post<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const update = async (dto: UpdateCarRentDto): Promise<Resource> => {
  const res = await API.put<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const getOne = async (id: string): Promise<Res<Resource>> => {
  const res = await API.get<Res<Resource>>(`${adminResourcePath}/${id}`, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${adminResourcePath}/${id}`, AxiosConfig())
}

const list = async (
  search: string,
  page: number,
  startedAt: Date | null,
  endedAt: Date | null
): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(adminResourcePath, {
    params: {
      search,
      page,
      startedAt,
      endedAt
    },
    headers: tryGetAPIHeaders(),
  })
  return res.data
}

const listByCarId = async (
  id: string,
  search: string,
  page: number,
  startedAt: Date | null,
  endedAt: Date | null
): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(`${adminResourcePath}/car/${id}`, {
    params: {
      search,
      page,
      startedAt,
      endedAt
    },
    headers: tryGetAPIHeaders(),
  })
  return res.data
}

const CarRentService = {
  create,
  update,
  getOne,
  deleteOne,
  list,
  listByCarId
}

export default CarRentService
