import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { User } from '../types/types'
import { CreateUserDto, UpdateUserDto } from '../validations/user.dto'

const resourcePath = '/users'
type Resource = User

const create = async (obj: { dto: CreateUserDto }): Promise<Resource> => {
  const { dto } = obj
  const res = await API.post<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  const resData = res.data.data
  return resData
}

const update = async (obj: { dto: UpdateUserDto }): Promise<Resource> => {
  const { dto } = obj
  const res = await API.put<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  const resData = res.data.data
  return resData
}

const getOne = async (id: string): Promise<Res<Resource>> => {
  const res = await API.get<Res<Resource>>(`${resourcePath}/${id}`, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${resourcePath}/${id}`, AxiosConfig())
}
export const list = async (
  search: string,
  page: number,
): Promise<Res<Paginated<User>>> => {
  const res = await API.get<Res<Paginated<User>>>('/users', {
    params: {
      search,
      page,
    },
    headers: tryGetAPIHeaders(),
  })
  return res.data
}

const UserService = {
  create,
  update,
  getOne,
  deleteOne,
  list
}

export default UserService
