export enum RQueryKeys {
  TopRented = 'TopRented',
  TopRentedThisMonth = 'TopRentedThisMonth',
  TotalRevenueThisYear = 'TotalRevenueThisYear',
  TotalRevenueThisMonth = 'TotalRevenueThisMonth',
  TotalRentsThisMonth = 'TotalRentsThisMonth',
  TotalRentsThisWeek = 'TotalRentsThisWeek',
  AverageDuration = 'AverageDuration',
  OccupancyRate = 'OccupancyRate',
  OccupancyRateThisMonth = 'OccupancyRateThisMonth',
  PopularRentalMonths = 'PopularRentalMonths',
  RentedByGender = 'RentedByGender',
  AverageRentByCity = 'AverageRentByCity',
  AverageExpenseCostByType = 'AverageExpenseCostByType',
  MostExpensiveExpenses = 'MostExpensiveExpenses',
  ExpenseCostAugmentRate = 'ExpenseCostAugmentRate',
  AgencyCars = 'AgencyCars',
  AgencyCar = 'AgencyCar',
  Car = 'Car',
  Cars = 'Cars',
  AgencyProcedures = 'AgencyProcedures',
  AgencyProcedure = 'AgencyProcedure',
  Clients = 'Clients',
  Client = 'Client',
  Apartments = 'Apartments',
  Apartment = 'Apartment',
  ApartmentRents = 'ApartmentsRent',
  ApartmentRent = 'ApartmentRent',
  CarRents = 'CarRents',
  CarRent = 'CarRent',
  Expenses = 'Expenses',
  Expense = 'Expense',
  Users = 'Users',
  User = 'User'
}

export const getAgencyCarQueryKey = (id: string) => `${RQueryKeys.AgencyCar}/${id}`
export const getAgencyProcedureQueryKey = (id: string) => `${RQueryKeys.AgencyProcedure}/${id}`
export const getClientQueryKey = (id: string) => `${RQueryKeys.Client}/${id}`
export const getApartmentQueryKey = (id: string) => `${RQueryKeys.Apartment}/${id}`
export const getCarQueryKey = (id: string) => `${RQueryKeys.Car}/${id}`
export const getCarRentQueryKey = (id: string) => `${RQueryKeys.CarRent}/${id}`
export const getExpenseQueryKey = (id: string) => `${RQueryKeys.Expense}/${id}`
export const getApartmentRentQueryKey = (id: string) => `${RQueryKeys.ApartmentRent}/${id}`
export const getUserQueryKey = (id: string) => `${RQueryKeys.User}/${id}`
