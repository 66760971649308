/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { ArrowBack, Edit } from '@mui/icons-material'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import moment from 'moment'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { formatCost } from '../../../../utils/cost.helper'
import AgencyProcedureService from '../../../../services/agency-procedure.service'
import { RQueryKeys, getAgencyProcedureQueryKey } from '../../../../types/react-query'
import { ELinks } from '../../../routes/links'

export const AgencyProcedures = ({ selectedProcedureId }: { selectedProcedureId: string }) => {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string}>()

  const { data } = useQuery(getAgencyProcedureQueryKey(id), async () => {
    const res = await AgencyProcedureService.getOne(id)
    return res.data
  }, { cacheTime: 1000 * 60 * 10 })

  const deleteMutation = useMutation(AgencyProcedureService.deleteOne, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.AgencyProcedure)
    }
  })
  const handleDelete = async () => {
    if (!data) return
    await deleteMutation.mutateAsync(data.id)
    goBack()
  }
  const goEdit = () => {
    history.push({
      pathname: ELinks.agencyProcedureForm,
      state: {
        mode: 'update',
        data
      }
    })
  }

  const goBack = () => history.push(ELinks.agencyProcedureView)

  return (
    <Grid
      style={{ width: '100%' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Stack
        sx={{
          width: '90%',
          gap: 2,
          padding: 1
        }}
      >
        <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="modal-modal-delete"
        aria-describedby="modal-modal-delete-car"
        sx={{
          display: 'flex',
          alignitems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Container
        maxWidth='sm'
        >
        <Box sx={{
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '2rem',
          borderRadius: '.8rem',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Borrar Tramite
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Desea borrar ${data?.description} ${data?.date} ?`}
          </Typography>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-around',
            marginY: '1rem',
            gap: '1rem'
          }}>
            <Button
              variant='outlined'
              onClick={() => setDeleteModalOpen(false)}
              disabled={deleteMutation.isLoading}
            >
              Cancelar
            </Button>
            <Button
              color='error'
              variant='contained'
              onClick={handleDelete}
              disabled={deleteMutation.isLoading}
            >
              {
                deleteMutation.isLoading
                && <CircularProgress size='1rem' />
              }
              Borrar
            </Button>
          </Box>
        </Box>
        </Container>
      </Modal>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '10%',
          justifyContent: 'space-between'
        }}>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <IconButton onClick={goEdit}>
            <Edit />
          </IconButton>
        </Box>

        <RoundedCard sx={{
          padding: '1rem',
          flexShrink: 0
        }}>
          <Typography variant='h6' fontWeight={600}>
            Descripcion
          </Typography>
          <Divider />
          <Stack gap={0.5}>
            <DescriptionRow label='Descripcion' value={data?.description || ''} />
            <DescriptionRow label='Precio' value={`$${formatCost(data?.cost || 0)}`} />
            <DescriptionRow label='Fecha' value={moment(data?.date).format('DD/MM/YYYY') || ''} />
            <DescriptionRow label='Facturado' value={data?.client? 'Sí' : 'No'} />
          </Stack>
        </RoundedCard>

        <RoundedCard sx={{
          padding: '1rem',
          flexShrink: 0
        }}>
          <Typography variant='h6' fontWeight={600}>
            Cliente
          </Typography>
          <Divider />
          {
          data?.client ? (

          <Stack gap={0.5}>
          <DescriptionRow label='Nombre' value={data?.client.fullName || ''} />
          <DescriptionRow label='Direccion' value={data?.client.fullAddress || ''} />
          <DescriptionRow label='Email' value={data?.client.email || ''} />
          <DescriptionRow label='Celular' value={data?.client.phoneNumber?.toString() || ''} />
          <DescriptionRow label='Sexo' value={data?.client.gender || ''} />
          </Stack>
          ) : (
          <Typography>No hay cliente asignado</Typography>
          )
          }
        </RoundedCard>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1rem'
        }}>
          <Button
            onClick={() => setDeleteModalOpen(true)}
            variant='text'
            size='large'
            color='error'
            sx={{
              width: '70%',
              marginY: '2rem'
            }}>
            ELIMINAR TRAMITE
          </Button>
        </Box>
      </Stack>
    </Grid>
  )
}

interface DescriptionRowProps {
  label: string
  value: string
}

const DescriptionRow = ({ label, value }: DescriptionRowProps) => (
  <Box sx={{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  }}>
    <Typography>{label}</Typography>
    <Typography>{value}</Typography>
  </Box>
)
