import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { Client } from '../types/types'
import { CreateClientDto, UpdateClientDto } from '../validations/client.dto'

const adminResourcePath = '/clients'
type Resource = Client

const create = async (dto: CreateClientDto): Promise<Resource> => {
  const res = await API.post<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const update = async (dto: UpdateClientDto): Promise<Resource> => {
  const res = await API.put<Res<Resource>>(adminResourcePath, dto, await AxiosConfig())
  return res.data.data
}

const getOne = async (id: string): Promise<Res<Resource>> => {
  const res = await API.get<Res<Resource>>(`${adminResourcePath}/${id}`, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${adminResourcePath}/${id}`, AxiosConfig())
}

export const list = async (
  search: string,
  page: number,
): Promise<Res<Paginated<Client>>> => {
  const res = await API.get<Res<Paginated<Client>>>('/clients', {
    params: {
      search,
      page,
    },
    headers: tryGetAPIHeaders(),
  })
  return res.data
}

const AddClientService = {
  create,
  update,
  getOne,
  deleteOne,
  list
}

export default AddClientService
