import { ArrowBack } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  debounce
} from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { DEFAULT_SHADOW } from '../../../../../styles/theme'
import { CreateAgencyProcedureDto, UpdateAgencyProcedureDto } from '../../../../../validations/agency-procedure.dto'
import { AgencyProcedure, Client } from '../../../../../types/types'
import createValidator from '../../../../../utils/class-validator-formik'
import AgencyProcedureService from '../../../../../services/agency-procedure.service'
import { RQueryKeys, getAgencyProcedureQueryKey } from '../../../../../types/react-query'
import { RoundedTextField } from '../../../../shared/material-rounded/RoundedTextField'
import { getFormikProps } from '../../../../../utils/formik.helper'
import { IOSSwitch } from '../../../../shared/Switch'
import { ELinks } from '../../../../routes/links'
import { list } from '../../../../../services/client.service'
import { Paginated, Res } from '../../../../../types/response.types'
import { DEBOUNCE_THRESHOLD } from '../../../../../config/debounce.config'

type Values = CreateAgencyProcedureDto | UpdateAgencyProcedureDto
const createInitialValues: CreateAgencyProcedureDto = {
  description: '',
  cost: 0,
  date: new Date(),
  clientId: undefined
}

export interface AgencyProcedureFormProps {
  mode: 'add' | 'update'
  data: AgencyProcedure
}

export const AgencyProcedureForm = () => {
  const history = useHistory()
  const [title, setTitle] = useState('Agregar tramite gestoria')
  const location = useLocation<AgencyProcedureFormProps>()
  const [initialValues, setInitialValues] = useState<Values>(createInitialValues)
  const { mode = 'add', data } = location.state || {}
  const queryClient = useQueryClient()
  const [hasClient, setHasClient] = useState(false)
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [searchClient, setSearchClient] = useState('')

  const createMutation = useMutation(AgencyProcedureService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.AgencyProcedure)
    },
  })

  const updateMutation = useMutation(AgencyProcedureService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.AgencyProcedure)
      if (data.id) {
        queryClient.invalidateQueries([getAgencyProcedureQueryKey(data.id)])
      }
    },
  })

  useEffect(() => {
    debounceRefetchUser()
  }, [searchClient])

  const handleSelectClient = (
    formik: FormikProps<CreateAgencyProcedureDto>,
    selectedClient: Client | null
  ) => {
    setSelectedClient(selectedClient)
    formik.setFieldValue('clientId', selectedClient?.id || '')
  }

  const clientsQueryKey = useQuery<Res<Paginated<Client>>, Error>(
    [],
    async () => {
      const res = await list(searchClient, 1)
      return res
    }
  )

  const debounceRefetchUser = useMemo(() => debounce(clientsQueryKey.refetch, DEBOUNCE_THRESHOLD), [])

  useEffect(() => {
    if (mode === 'add') setTitle('Agregar tramite gestoria')
    else if (mode === 'update') setTitle('Editar tramite gestoria')

    if (mode === 'update') {
      if (data.client) {
        setSelectedClient(data.client)
      }
      setInitialValues({
        id: data.id,
        cost: data.cost,
        date: data.date,
        description: data.description,
        clientId: data.clientId
      } as UpdateAgencyProcedureDto)
      if (data.client && data.client.id) {
        setHasClient(true)
        setSelectedClient(data.client)
      }
    }
  }, [mode, data])

  const onSubmit = async (values: Values) => {
    if (hasClient === false) {
      values.clientId = null
    }
    if (mode === 'add') {
      await createMutation.mutateAsync(values as CreateAgencyProcedureDto)
    } else if (mode === 'update') {
      await updateMutation.mutateAsync(values as UpdateAgencyProcedureDto)
    }
    history.push(ELinks.agencyProcedureView)
  }

  const validate = mode === 'add'
    ? createValidator(CreateAgencyProcedureDto)
    : createValidator(UpdateAgencyProcedureDto)

  const handleSetNumber = (
    str: string,
    setFn: (value: any) => void,
  ) => {
    if (str === '') {
      setFn('')
      return
    }

    const value = parseFloat(str)
    if (Number.isNaN(value)) return

    setFn(value)
  }
  const isLoading = createMutation.isLoading || updateMutation.isLoading

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      {
        isLoading
        && <Box sx={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 10
        }}>
          <CircularProgress />
          <Typography>Guardando...</Typography>
        </Box>
      }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Stack
            width='90%'
            height={'100%'}
            gap={2}
            alignItems={'center'}
          >
            <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>{title}</Typography>
            </Box>

            <RoundedTextField
              required
              label='Descripcion'
              {...getFormikProps(formik, 'description')}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : undefined
              }
            />
            <RoundedTextField
              required
              label='Precio'
              value={formik.values.cost}
              onChange={(e) => {
                handleSetNumber(e.target.value, (val) => {
                  formik.setFieldValue('cost', val)
                })
              }}
              onBlur={formik.handleBlur('cost')}
              error={formik.touched.cost && Boolean(formik.errors.cost)}
              helperText={
                formik.touched.cost && formik.errors.cost
                  ? formik.errors.cost
                  : undefined
              }
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Fecha"
                value={formik.values.date || null}
                onChange={(newValue: Date | null) => {
                  formik.setFieldValue('date', newValue)
                }}
                renderInput={(params: TextFieldProps) => (
                  <RoundedTextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '.2rem',
                marginTop: '4rem',
              }}
            >
              <IOSSwitch
                checked={hasClient}
                onChange={() => {
                  setHasClient((prevHasClient) => !prevHasClient)
                }}
              />
              <Typography>
                Guardar cliente?
              </Typography>
            </Box>
            {hasClient && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '.2rem',
                    width: '100%'
                  }}
                >
                  <PersonAddIcon
                    sx={{
                      backgroundColor: 'white',
                      boxShadow: `${DEFAULT_SHADOW}`,
                      width: 40,
                      height: 40,
                      padding: '.4rem',
                      borderRadius: '2rem',
                      cursor: 'pointer'
                    }}
                    onClick={() => history.push(ELinks.clientForm)}
                  />
                  <Autocomplete
                    loading={clientsQueryKey.isLoading}
                    fullWidth
                    id="combo-box-user"
                    options={clientsQueryKey.data?.data.rows || []}
                    getOptionLabel={(option: Client) => `${option.fullName}`}
                    isOptionEqualToValue={(option: Client) => option.id === selectedClient?.id}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        label="Cliente"
                        fullWidth
                        variant="standard"
                        error={formik.touched.clientId && Boolean(formik.errors.clientId)}
                        helperText={formik.touched.clientId && formik.errors.clientId}
                      />
                    )}
                    onInputChange={(_, value) => setSearchClient(value)}
                    value={selectedClient}
                    onChange={(e: any, value: Client | null) => {
                      handleSelectClient(formik as FormikProps<CreateAgencyProcedureDto>, value)
                    }}
                  />
                </Box>
              </>
            )}
            <Button
              variant='contained'
              sx={{ width: '40%' }}
              size='large'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress />}
              Guardar
            </Button>
          </Stack>
        )}
      </Formik>
    </Grid>
  )
}
