/* eslint-disable import/no-duplicates */
import {
  Box, Button, CircularProgress, Grid, IconButton, MenuItem, Stack, Typography
} from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { ArrowBack } from '@mui/icons-material'
import { RQueryKeys, getUserQueryKey } from '../../../../../types/react-query'
import { User } from '../../../../../types/types'
import { CreateUserDto, UpdateUserDto } from '../../../../../validations/user.dto'
import { RoundedTextField } from '../../../../shared/material-rounded/RoundedTextField'
import { getFormikProps } from '../../../../../utils/formik.helper'
import createValidator from '../../../../../utils/class-validator-formik'
import { RoundedSelect } from '../../../../shared/material-rounded/RoundedSelect'
import UserService from '../../../../../services/user.service'

type Values = CreateUserDto | UpdateUserDto

const createInitialValues: CreateUserDto = {
  fullName: '',
  email: '',
  password: '',
  type: 'admin' || 'user'
}

export interface UserFormProps {
  mode: 'add' | 'update'
  data: User
}

export const UserForm = () => {
  const history = useHistory()
  const [title, setTitle] = useState('Agregar usuario')
  const location = useLocation<UserFormProps>()
  const { mode = 'add', data } = location.state || {}
  const [initialValues, setInitialValues] = useState<Values>(
    mode === 'add' ? createInitialValues : { ...createInitialValues, password: '' }
  )
  const queryClient = useQueryClient()

  useEffect(() => {
    if (mode === 'add') setTitle('Agregar usuario')
    else if (mode === 'update') setTitle('Editar usuario')

    if (mode === 'update') {
      setInitialValues({
        ...data
      } as UpdateUserDto)
    }
  }, [mode, data])

  const createMutation = useMutation(UserService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.User)
    }
  })

  const updateMutation = useMutation(UserService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.User)
      queryClient.invalidateQueries(getUserQueryKey(data.id))
    }
  })
  const validate = mode === 'add'
    ? createValidator(CreateUserDto)
    : createValidator(UpdateUserDto)

  const isLoading = createMutation.isLoading || updateMutation.isLoading

  const onSubmit = async (values: Values, formik: FormikHelpers<Values>) => {
    if (mode === 'add') {
      await createMutation.mutateAsync({
        dto: values as CreateUserDto
      })
    } else {
      await updateMutation.mutateAsync({
        dto: values as UpdateUserDto
      })
    }
    formik.resetForm()
    history.goBack()
  }

  return (

    <Grid
      style={{ height: '100vh' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      {
        isLoading
        && <Box sx={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 10
        }}>
          <CircularProgress />
          <Typography>Guardando...</Typography>
        </Box>
      }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Stack
            width='90%'
            height={'100%'}
            gap={2}
            alignItems={'center'}
          >
            <>
              {console.log(formik.errors)}
            </>
            <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>{title}</Typography>
            </Box>
            <RoundedTextField
              autoFocus
              required
              placeholder='Nombre completo'
              label='Nombre Completo'
              {...getFormikProps(formik, 'fullName')}
            />
            <RoundedTextField
              required
              label='Email'
              placeholder='example@email.com'
              {...getFormikProps(formik, 'email')}
            />
            {mode === 'add' && (
              <RoundedTextField
                required
                label="Contraseña"
                placeholder="******"
                type="password"
                {...getFormikProps(formik, 'password')}
              />
            )}
            <RoundedSelect
              label='Tipo de usuario'
              sx={{
                width: '100%'
              }}
              name='type'
              onChange={(event) => {
                formik.handleChange(event)
              }}
              value={`${formik.values.type}`}
              error={formik.touched.type && Boolean(formik.errors.type)}
            >
              <MenuItem value={'admin'}>Admin</MenuItem>
              <MenuItem value={'user'}>User</MenuItem>
            </RoundedSelect>

            <Button
              variant='contained'
              sx={{ width: '40%' }}
              size='large'
              onClick={() => formik.handleSubmit()}
            >
              {
                isLoading
                && <CircularProgress />
              }
              Guardar
            </Button>
          </Stack>
        )}
      </Formik>
    </Grid >
  )
}
