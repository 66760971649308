/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { ArrowBack, Edit } from '@mui/icons-material'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { RQueryKeys, getUserQueryKey } from '../../../../types/react-query'
import { ELinks } from '../../../routes/links'
import UserService from '../../../../services/user.service'

interface DescriptionRowProps {
  label: string
  value: string
}

const DescriptionRow = ({ label, value }: DescriptionRowProps) => (
  <Box sx={{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  }}>
    <Typography>{label}</Typography>
    <Typography>{value}</Typography>
  </Box>
)

export const UsersView = () => {
  const history = useHistory()
  const { id } = useParams<any>()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const queryClient = useQueryClient()

  const { data } = useQuery(getUserQueryKey(id), async () => {
    const res = await UserService.getOne(id)
    return res.data
  }, { cacheTime: 1000 * 60 * 10 })

  const deleteMutation = useMutation(UserService.deleteOne, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.User)
    }
  })

  const goBack = () => history.push(ELinks.users)

  const handleDelete = async () => {
    if (!data) return
    await deleteMutation.mutateAsync(data.id)
    goBack()
  }
  const goEdit = () => {
    console.log('first')
    history.push({
      pathname: ELinks.userForm,
      state: {
        mode: 'update',
        data
      }
    })
  }

  if (!data) {
    return (
      <CircularProgress />
    )
  }

  const isBaseAdmin = data?.email === 'admin@system.com'

  return (
    <Grid
      style={{ width: '100%' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Stack
        sx={{
          width: '90%',
          gap: 2,
          padding: 1
        }}
      >
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby="modal-modal-delete"
          aria-describedby="modal-modal-delete-user"
          sx={{
            display: 'flex',
            alignitems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Container
            maxWidth='sm'
          >
            <Box sx={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              borderRadius: '.8rem',
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Borrar usuario
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {`Desea borrar ${data?.fullName}?`}
              </Typography>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                marginY: '1rem',
                gap: '1rem'
              }}>
                <Button
                  variant='outlined'
                  onClick={() => setDeleteModalOpen(false)}
                  disabled={deleteMutation.isLoading}
                >
                  Cancelar
                </Button>
                <Button
                  color='error'
                  variant='contained'
                  onClick={handleDelete}
                  disabled={deleteMutation.isLoading}
                >
                  {
                    deleteMutation.isLoading
                    && <CircularProgress size='1rem' />
                  }
                  Borrar
                </Button>

              </Box>
            </Box>
          </Container>
        </Modal>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '10%',
          justifyContent: 'space-between'
        }}>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
          {
            !isBaseAdmin
            && <IconButton onClick={goEdit}>
              <Edit />
            </IconButton>
          }
        </Box>

        <RoundedCard sx={{
          padding: '1rem',
          flexShrink: 0
        }}>
          <Typography variant='h6' fontWeight={600}>
            Usuario
          </Typography>
          <Divider />
          {data ? (
            <Stack gap={0.5}>
              <DescriptionRow label='Nombre' value={data?.fullName || ''} />
              <DescriptionRow label='Email' value={data?.email || ''} />
            </Stack>
          ) : (
            <Typography>No hay usuario asignado</Typography>
          )}
        </RoundedCard>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1rem'
        }}>
          {
            !isBaseAdmin
            && <Button
              onClick={() => setDeleteModalOpen(true)}
              variant='text'
              size='large'
              color='error'
              sx={{
                width: '70%',
                marginY: '2rem'
              }}>
              ELIMINAR USUARIO
            </Button>
          }
        </Box>
      </Stack>
    </Grid>
  )
}
