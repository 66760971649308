export enum ELinks {
  home = '/home',
  login = '/auth',
  dashboard = '/admin/dashboard',
  agencyCars = '/admin/agency-cars',
  agencyCarForm = '/admin/agency-car/form',
  agencyCar = '/admin/agency-car',
  apartments = '/admin/apartments',
  apartmentForm = '/admin/apartment/form',
  apartmentRents = '/admin/apartment-rents',
  apartmentRent = '/admin/apartment-rent',
  apartmentRentForm = '/admin/apartment-rents/form',
  cars = '/admin/cars',
  carForm = '/admin/car/form',
  carsRented = '/admin/cars/rented',
  carsView = '/admin/car',
  carRents = '/admin/car-rents',
  carRent = '/admin/car-rent',
  carRentForm = '/admin/car-rents/form',
  agencyProcedureView = '/admin/agency-procedures',
  agencyProcedures = '/admin/agency-procedure',
  agencyProcedureForm = '/admin/agency-procedures/form',
  clientForm = '/admin/client/form',
  clients = '/admin/clients',
  client = '/admin/client',
  changePassword = '/admin/auth/profile/update-password',
  users = '/admin/users',
  user = '/admin/user',
  userForm = '/admin/user/form'

}
