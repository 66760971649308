import React from 'react'
import { Box, Typography } from '@mui/material'
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryLabel,
  VictoryContainer,
  VictoryTooltip,
} from 'victory'
import { Chart } from '../../../../types/types'
import { formatPrice } from '../../../../utils/price.helper'

export const BarChart = ({
  data,
  label,
  formatAsPrice,
  withTooltip
}: {
  data: Chart[] | undefined
  label?: string
  formatAsPrice?: boolean
  withTooltip?: boolean
}) => (data ? (
  <Box>
    {label && (
      <Typography variant='h5' component='h2'>
        {label}
      </Typography>
    )}

    <VictoryChart
      domainPadding={{ x: 40, y: 30 }}
      padding={{
        top: 10,
        bottom: 100,
        left: 80,
        right: 20,
      }}
      containerComponent={<VictoryContainer responsive={false} />}
    >
      <VictoryAxis
        tickFormat={(tick) => (formatAsPrice ? `$${formatPrice(tick)}` : `${tick}`)}
        dependentAxis
        style={{
          grid: {
            stroke: '#e5e5e5',
            strokeWidth: 1,
            strokeDasharray: '10, 5'
          }
        }}
      />
        <VictoryAxis
        tickLabelComponent={
          <VictoryLabel angle={-45} textAnchor='end' dy={-5} dx={10} />
        }
        />
        <VictoryBar
        labels={({ datum }) => (formatAsPrice ? `$${formatPrice(datum.y)}` : `${datum.y}`)
        }
          data={data}
        labelComponent={
          withTooltip
            ? <VictoryTooltip cornerRadius={2} pointerLength={0} centerOffset={{ y: -2 }} />
            : <VictoryLabel />
        }
          style={{ data: { width: 20 } }}
        />
      </VictoryChart>
    </Box>
) : (
  <></>
))
