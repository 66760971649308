import {
  Add,
  EventNote,
} from '@mui/icons-material'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  Typography
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { list } from '../../../../services/agency-procedure.service'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { TypographyIcon } from '../../../shared/material-rounded/TypographyIcon'
import { formatCost } from '../../../../utils/cost.helper'
import { ELinks } from '../../../routes/links'
import { RQueryKeys, getAgencyProcedureQueryKey } from '../../../../types/react-query'
import { AgencyProcedure } from '../../../../types/types'

export const AgencyProcedureView = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()

  const agencyProcedureQuery = useQuery(
    [RQueryKeys.AgencyProcedure, page],
    async () => {
      const res = await list(search, page)
      return res
    }
  )

  const debounceSearchRefetch = useMemo(() => debounce(agencyProcedureQuery.refetch, 500), [])

  useEffect(() => {
    debounceSearchRefetch()
  }, [search])

  const viewProcedure = (procedure: AgencyProcedure) => {
    queryClient.setQueryData(getAgencyProcedureQueryKey(procedure.id), procedure)
    history.push({
      pathname: `${ELinks.agencyProcedures}/${procedure.id}`,
      state: procedure,
    })
  }

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      maxWidth='xs'
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Stack
        width='90%'
        height={'100%'}
        gap={2}
        alignItems={'center'}
      >
        <Button
          startIcon={<Add />}
          variant='contained'
          sx={{ width: '60%' }}
          onClick={() => history.push(ELinks.agencyProcedureForm)}
        >
          Agregar Tramite
        </Button>
        <RoundedTextField
          label='Buscar'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {
          (agencyProcedureQuery.data?.data.rows || []).map((procedure) => (
            <RoundedCard
              key={procedure.id}
              sx={{
                width: '100%',
                flexShrink: 0,
              }}
              onClick={() => viewProcedure(procedure)}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '1.3rem'
              }}>
                <Stack gap={1}>
                  <TypographyIcon startIcon={ReceiptLongIcon}>{procedure.description}</TypographyIcon>
                  <TypographyIcon startIcon={EventNote}>{moment(procedure.date).format('DD/MM/YY')}</TypographyIcon>
                  {
                    procedure.client ? (
                      <TypographyIcon startIcon={PersonIcon}>{procedure.client.fullName}</TypographyIcon>
                    ) : (
                      <TypographyIcon startIcon={PersonIcon}>No hay cliente asignado</TypographyIcon>
                    )}
                </Stack>
                <Typography variant='h5' fontWeight={600}>${formatCost(procedure.cost)}</Typography>
              </Box>
            </RoundedCard>
          ))
        }

        {
          agencyProcedureQuery.data
          && <Pagination
            count={agencyProcedureQuery.data?.data.metadata.totalPages}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        }
      </Stack>
    </Grid >

  )
}
