import axios from 'axios'
import { ErrorData, errorsCatalogs } from '../types/errors.catalogs'

export const getErrorMessage = (code: string): string => {
  const errorData = (errorsCatalogs as any)[code] as ErrorData
  if (!errorData) return 'Error desconocido'
  return errorData.message
}

export const getQueryError = (error: any) => {
  if (axios.isAxiosError(error)) {
    const { code } = error.response!.data as ErrorData
    const message = getErrorMessage(code)
    return message
  }
  return 'Error desconocido'
}
