import React from 'react'
import { Box, Typography } from '@mui/material'
import {
  VictoryAxis,
  VictoryChart, VictoryLabel, VictoryLine, VictoryScatter, VictoryTooltip, VictoryVoronoiContainer
} from 'victory'
import { Chart } from '../../../../types/types'

export const LineChart = ({
  data,
  label,
}: {
  data: Chart[] | undefined
  label?: string
}) => (
  data
    ? <Box>
      {label && <Typography variant='h5' component='h2'>{label}</Typography>}
      <VictoryChart
        domainPadding={{ x: 40, y: 30 }}
        padding={{
          top: 10, bottom: 100, left: 80, right: 20
        }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis
          dependentAxis
          style={{
            grid: {
              stroke: '#e5e5e5',
              strokeWidth: 1,
              strokeDasharray: '10, 5'
            }
          }}
        />
        <VictoryAxis
          tickLabelComponent={<VictoryLabel angle={-45} textAnchor='end' dy={-5} dx={10} />}
          style={{
            grid: {
              stroke: '#e5e5e5',
              strokeWidth: 1,
              strokeDasharray: '10, 5'
            }
          }}
        />
        <VictoryLine
          labels={
            ({ datum }: { datum: Chart }) => (datum.tooltip ? `${datum.tooltip}` : `${datum.y}`)
          }
          data={data}
          labelComponent={<VictoryTooltip pointerLength={0} cornerRadius={2} />}
        />
        <VictoryScatter data={data} />
      </VictoryChart>
    </Box >
    : <></>
)
