import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Formik, FormikHelpers } from 'formik'
import { ArrowBack } from '@mui/icons-material'
import createValidator from '../../../../utils/class-validator-formik'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { getFormikProps } from '../../../../utils/formik.helper'
import { CreateCarDto, UpdateCarDto } from '../../../../validations/car.dto'
import { Car } from '../../../../types/types'
import CarService from '../../../../services/basics/car.service'
import { ELinks } from '../../../routes/links'

interface Location {
  mode: string,
  data?: Car
}

type Values = CreateCarDto | UpdateCarDto

const createInitialValues: CreateCarDto = {
  make: '',
  model: '',
  color: '',
  yearOfManufacturing: new Date().getFullYear()
}

export const CarForm = () => {
  const history = useHistory()
  const location = useLocation<Location>()
  const { data, mode = 'add' } = location.state

  const [title, setTitle] = useState('Agregar auto')
  const [initialValues, setInitialValues] = useState<Values>(createInitialValues)
  const queryClient = useQueryClient()

  const handleSetNumber = (
    str: string,
    setFn: (value: any) => void,
  ) => {
    if (str === '') {
      setFn('')
      return
    }

    const value = parseFloat(str)
    if (Number.isNaN(value)) return

    setFn(value)
  }

  useEffect(() => {
    if (mode === 'add') setTitle('Agregar auto')
    else if (mode === 'update') setTitle('Editar auto')

    if (mode === 'update' && data) {
      setInitialValues({
        ...data
      })
    }
  }, [mode, data])

  const onSuccess = () => { queryClient.invalidateQueries() }
  const createMutation = useMutation(CarService.create, { onSuccess })
  const updateMutation = useMutation(CarService.update, { onSuccess })

  const onSubmit = async (values: Values, formik: FormikHelpers<Values>) => {
    if (mode === 'add') {
      await createMutation.mutateAsync(values as CreateCarDto)
    } else {
      await updateMutation.mutateAsync(values as UpdateCarDto)
    }
    formik.resetForm()
    history.replace(ELinks.cars)
  }

  const validate = mode === 'add'
    ? createValidator(CreateCarDto)
    : createValidator(UpdateCarDto)

  const isLoading = createMutation.isLoading || updateMutation.isLoading

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Stack
            width='90%'
            height={'100%'}
            gap={2}
            alignItems={'center'}
          >

            <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>{title}</Typography>
            </Box>

            <Stack
              width='100%'
              gap={2}
              alignItems={'center'}
              padding='0.5rem'
            >
              <RoundedTextField
                required
                label='Marca'
                {...getFormikProps(formik, 'make')}
              />
              <RoundedTextField
                required
                label='Modelo'
                {...getFormikProps(formik, 'model')}
              />
              <RoundedTextField
                required
                label='Color'
                {...getFormikProps(formik, 'color')}
              />
              <RoundedTextField
                required
                label='Año'
                {...getFormikProps(formik, 'yearOfManufacturing')}
                onChange={(e) => handleSetNumber(
                  e.target.value,
                  (val) => formik.setFieldValue('yearOfManufacturing', val)
                )}
              />

            </Stack>
            <Button
              variant='contained'
              sx={{ width: '40%' }}
              size='large'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            >
              {
                isLoading
                && <CircularProgress />
              }
              Guardar
            </Button>
          </Stack>
        )}
      </Formik>
    </Grid>
  )
}
