import {
  IsEmail,
  IsNotEmpty,
  IsString,
  Length,
} from 'class-validator'
import { Match } from '../../utils/decorator.helper'

export class LoginDto {
  @IsEmail(undefined, { message: 'Ingrese un email válido.' })
  email: string

  @IsString()
  @IsNotEmpty({ message: 'Ingrese la contraseña.' })
  @Length(6, 30, { message: 'La contraseña debe tener entre 6 y 30 caracteres' })
  password: string
}

export class LogoutDto {
  @IsString()
  token: string // device token from expo
}

export class UpdatePasswordDto {
  @IsString()
  @Length(6, 30, { message: 'La contraseña debe tener entre 6 y 30 caracteres' })
  currentPassword: string

  @IsString()
  @Length(6, 30, { message: 'La contraseña debe tener entre 6 y 30 caracteres' })
  newPassword: string

  @IsString()
  @Length(6, 30, { message: 'La contraseña debe tener entre 6 y 30 caracteres' })
  @Match('newPassword', { message: 'Las contraseñas no coinciden' })
  repeatNewPassword: string
}
