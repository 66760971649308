/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { ArrowBack, Edit } from '@mui/icons-material'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import {
  RQueryKeys,
  getApartmentRentQueryKey,
} from '../../../../types/react-query'
import { ELinks } from '../../../routes/links'
import ApartmentRentService from '../../../../services/basics/apartment-rent.service'
import { localeDateOptions } from '../../../../utils/time.helper'

export const ApartmentRent = () => {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()

  const apartmentRentQuery = useQuery(
    getApartmentRentQueryKey(id),
    async () => {
      const res = await ApartmentRentService.getOne(id)
      return res.data
    },
    { cacheTime: 1000 * 60 * 10 }
  )

  const deleteMutation = useMutation(ApartmentRentService.deleteOne, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.ApartmentRents)
    },
  })

  const handleDelete = async () => {
    if (!apartmentRentQuery.data) return
    await deleteMutation.mutateAsync(apartmentRentQuery.data.id)
    goBack()
  }

  const goEdit = () => {
    history.push({
      pathname: ELinks.apartmentRentForm,
      state: {
        mode: 'update',
        data: apartmentRentQuery.data,
      },
    })
  }

  const goBack = () => history.push(ELinks.apartmentRents)

  return (
    <Grid
      style={{ width: '100%' }}
      container
      alignItems='center'
      justifyContent='center'
      flexDirection={'column'}
    >
      <Stack
        sx={{
          width: '90%',
          gap: 2,
          padding: 1,
        }}
      >
        {apartmentRentQuery.data && (
          <Modal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            aria-labelledby='modal-modal-delete'
            aria-describedby='modal-modal-delete-car'
            sx={{
              display: 'flex',
              alignitems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Container maxWidth='sm'>
              <Box
                sx={{
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '2rem',
                  borderRadius: '.8rem',
                }}
              >
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Borrar Alquiler
                </Typography>
                <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                  {
                    `Desea borrar este alquiler del apartamento ${apartmentRentQuery.data?.apartment?.fullAddress || ''}
                  ${apartmentRentQuery.data?.apartment?.city || ''}?`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginY: '1rem',
                    gap: '1rem',
                  }}
                >
                  <Button
                    variant='outlined'
                    onClick={() => setDeleteModalOpen(false)}
                    disabled={deleteMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color='error'
                    variant='contained'
                    onClick={handleDelete}
                    disabled={deleteMutation.isLoading}
                  >
                    {deleteMutation.isLoading && (
                      <CircularProgress size='1rem' />
                    )}
                    Borrar
                  </Button>
                </Box>
              </Box>
            </Container>
          </Modal>
        )}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '10%',
            justifyContent: 'space-between',
          }}
        >
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
        </Box>
        {!apartmentRentQuery.isLoading && apartmentRentQuery.data && (
          <RoundedCard
            sx={{
              padding: '.5rem',
              flexShrink: 0,
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography variant='h6' fontWeight={600}>
                Descripcion
              </Typography>
              <IconButton onClick={goEdit}>
                <Edit fontSize='small' />
              </IconButton>
            </Stack>
            <Divider />
            <Stack mt='.5rem' gap={0.5}>
              <DescriptionRow
                label='Apartamento'
                value={`${apartmentRentQuery.data?.apartment?.fullAddress || ''} 
                ${apartmentRentQuery.data?.apartment?.city || ''}`}
              />
              <DescriptionRow
                label='Precio'
                value={`$${apartmentRentQuery.data?.cost}`}
              />
              <DescriptionRow
                label='Desde'
                value={new Date(
                  apartmentRentQuery.data?.startedAt
                ).toLocaleString('es-AR', localeDateOptions)}
              />
              <DescriptionRow
                label='Hasta'
                value={new Date(
                  apartmentRentQuery.data?.endedAt
                ).toLocaleString('es-AR', localeDateOptions)}
              />
            </Stack>
          </RoundedCard>
        )}
        {!apartmentRentQuery.isLoading && apartmentRentQuery.data?.client && (
          <RoundedCard
            sx={{
              padding: '.5rem',
              flexShrink: 0,
            }}
          >
            <Typography variant='h6' fontWeight={600}>
              Cliente
            </Typography>
            <Divider />
            <Stack mt='.5rem' gap={0.5}>
              <DescriptionRow
                label='Nombre'
                value={apartmentRentQuery.data.client.fullName}
              />
              <DescriptionRow
                label='Email'
                value={apartmentRentQuery.data.client.email || ''}
              />
              <DescriptionRow
                label='Celular'
                value={apartmentRentQuery.data.client.phoneNumber || ''}
              />
              <DescriptionRow
                label='Sexo'
                value={apartmentRentQuery.data.client.gender || ''}
              />
            </Stack>
          </RoundedCard>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <Button
            onClick={() => setDeleteModalOpen(true)}
            variant='text'
            size='large'
            color='error'
            sx={{
              width: '70%',
              marginY: '2rem',
            }}
          >
            ELIMINAR ALQUILER
          </Button>
        </Box>
      </Stack>
    </Grid>
  )
}

interface DescriptionRowProps {
  label: string
  value: string
}

const DescriptionRow = ({ label, value }: DescriptionRowProps) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    }}
  >
    <Typography>{label}</Typography>
    <Typography>{value}</Typography>
  </Box>
)
