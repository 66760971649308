import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextFieldProps,
  Typography,
  Autocomplete,
} from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { ArrowBack, Apartment as ApartmentIcon } from '@mui/icons-material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { IOSSwitch } from '../../../shared/Switch'
import { list } from '../../../../services/client.service'
import { getFormikProps } from '../../../../utils/formik.helper'
import { ApartmentRent, Client } from '../../../../types/types'
import { ELinks } from '../../../routes/links'
import { DEFAULT_SHADOW } from '../../../../styles/theme'
import { CreateApartmentRentDto, UpdateApartmentRentDto } from '../../../../validations/apartment-rent.dto'
import ApartmentRentService from '../../../../services/basics/apartment-rent.service'

interface Location {
  mode: string
  data?: ApartmentRent
}
const createInitialValues: CreateApartmentRentDto = {
  cost: 0,
  startedAt: new Date().toISOString(),
  endedAt: new Date().toISOString(),
  apartmentId: ''
}

type Values = CreateApartmentRentDto | UpdateApartmentRentDto

export const ApartmentRentForm = () => {
  const history = useHistory()
  const location = useLocation<Location>()
  const { data, mode = 'add' } = location.state

  const [hasClient, setHasClient] = useState(false)
  const [title, setTitle] = useState('Agregar apartamento')
  const [initialValues, setInitialValues] = useState<Values>(createInitialValues)
  const queryClient = useQueryClient()

  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [searchClient, setSearchClient] = useState('')

  const clientsQuery = useQuery(
    [RQueryKeys.Clients, searchClient],
    () => list(searchClient, 1)
  )

  const handleSelectClient = (
    formik: FormikProps<Values>,
    value: Client | null
  ) => {
    setSelectedClient(value)
    formik.setFieldValue('clientId', value?.id || '')
  }

  useEffect(() => {
    if (mode === 'add') setTitle('Agregar alquiler de apartamento')
    else if (mode === 'update') setTitle('Editar alquiler de apartamento')
    if (mode === 'update' && data) {
      setInitialValues({
        ...data,
        apartmentId: data.apartment?.id
      })
      if (data.client) {
        setSelectedClient(data.client)
        setHasClient(true)
      }
    }
    if (mode === 'add' && data) {
      setInitialValues({
        ...createInitialValues,
        apartmentId: data.apartment?.id
      })
    }
  }, [mode, data])

  const onSuccess = () => {
    queryClient.invalidateQueries(RQueryKeys.Apartments)
  }
  const createMutation = useMutation(ApartmentRentService.create, { onSuccess })
  const updateMutation = useMutation(ApartmentRentService.update, { onSuccess })

  const onSubmit = async (values: Values, formik: FormikHelpers<Values>) => {
    if (mode === 'add') {
      await createMutation.mutateAsync(values as CreateApartmentRentDto)
    } else {
      await updateMutation.mutateAsync(values as UpdateApartmentRentDto)
    }
    formik.resetForm()
    history.replace(ELinks.apartmentRents)
  }

  const validate = mode === 'add'
    ? createValidator(CreateApartmentRentDto)
    : createValidator(UpdateApartmentRentDto)

  const isLoading = createMutation.isLoading || updateMutation.isLoading

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      alignItems='center'
      justifyContent='center'
      flexDirection={'column'}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Stack width='90%' height={'100%'} gap={2} alignItems={'center'}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>{title}</Typography>
            </Box>
            <Box display='flex' gap='1.5rem' mb='2rem'>
              <Box
                borderRadius='10px'
                padding='1rem 1.5rem'
                sx={{ boxShadow: DEFAULT_SHADOW }}
              >
                <ApartmentIcon
                  sx={{
                    width: '4rem',
                    height: '4rem',
                  }}
                />
              </Box>
              <Stack gap='1rem'>
                <Typography>{data?.apartment?.fullAddress || ''}</Typography>
                <Typography>{data?.apartment?.city || ''}</Typography>
              </Stack>
            </Box>
            <Stack width='100%' gap={3} alignItems={'center'} padding='0.5rem'>
              <RoundedTextField
                required
                label='Precio'
                type='number'
                {...getFormikProps(formik, 'cost')}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack direction='row' gap={3}>
                  <DatePicker
                    onChange={(newValue: Date | null) => {
                      // hacky thing to avoid error and not modify dto
                      if (newValue?.toString() !== 'Invalid Date') {
                        formik.setFieldValue('startedAt', newValue?.toISOString())
                      } else {
                        formik.setFieldValue('startedAt', null)
                      }
                    }}
                    value={formik.values.startedAt}
                    label='Desde'
                    inputFormat='dd/MM/yyyy'
                    renderInput={(params: TextFieldProps) => (
                      <RoundedTextField
                        required
                        {...params}
                        variant='outlined'
                        error={formik.touched.startedAt && Boolean(formik.errors.startedAt)}
                        helperText={formik.touched.startedAt && formik.errors.startedAt}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    )}
                  />
                  <DatePicker
                    onChange={(newValue: Date | null) => {
                      // hacky thing to avoid error and not modify dto
                      if (newValue?.toString() !== 'Invalid Date') {
                        formik.setFieldValue('endedAt', newValue?.toISOString())
                      } else {
                        formik.setFieldValue('endedAt', null)
                      }
                    }}
                    value={formik.values.endedAt}
                    label='Hasta'
                    inputFormat='dd/MM/yyyy'
                    renderInput={(params: TextFieldProps) => (
                      <RoundedTextField
                        required
                        {...params}
                        variant='outlined'
                        error={formik.touched.endedAt && Boolean(formik.errors.endedAt)}
                        helperText={formik.touched.endedAt && formik.errors.endedAt}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <FormControlLabel
                sx={{
                  marginTop: '3rem',
                }}
                onChange={() => {
                  setHasClient((prev) => !prev)
                }}
                label='Guardar cliente?'
                control={
                  <IOSSwitch
                    checked={hasClient}
                    sx={{ marginRight: '.5rem' }}
                  />
                }
              />
              {hasClient && (
                <Box
                  alignItems='center'
                  display='flex'
                  gap='.5rem'
                  width='100%'
                >
                  <IconButton>
                    <PersonAddIcon
                      sx={{
                        backgroundColor: 'white',
                        boxShadow: `${DEFAULT_SHADOW}`,
                        width: 40,
                        height: 40,
                        padding: '.4rem',
                        borderRadius: '2rem',
                      }}
                    />
                  </IconButton>
                  <Autocomplete
                    loading={clientsQuery.isLoading}
                    options={clientsQuery.data?.data.rows || []}
                    getOptionLabel={(option: Client) => `${option.fullName}`}
                    isOptionEqualToValue={(option: Client) => option.id === selectedClient?.id
                    }
                    value={selectedClient}
                    onInputChange={(_, value) => setSearchClient(value)}
                    onChange={(e: any, value: Client | null) => {
                      handleSelectClient(formik as FormikProps<Values>, value)
                    }}
                    fullWidth
                    renderInput={(params: TextFieldProps) => (
                      <RoundedTextField
                        {...params}
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        size='small'
                        placeholder='Seleccionar cliente'
                      />
                    )}
                  />
                </Box>
              )}
            </Stack>
            <Button
              variant='contained'
              sx={{ width: '40%' }}
              size='large'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress />}
              Guardar
            </Button>
          </Stack>
        )}
      </Formik>
    </Grid>
  )
}
