import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  Typography,
  debounce,
  styled
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  Apartment as ApartmentIcon, Place,
} from '@mui/icons-material'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { RQueryKeys } from '../../../../types/react-query'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { TypographyIcon } from '../../../shared/material-rounded/TypographyIcon'
import ApartmentService from '../../../../services/basics/apartment.service'
import { ELinks } from '../../../routes/links'
import { Apartment } from '../../../../types/types'

export const ApartmentsView = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const query = useQuery([RQueryKeys.Apartments, page], async () => {
    const res = await ApartmentService.list(search, page)
    return res
  })

  const refetch = useMemo(() => debounce(query.refetch, 500), [])
  useEffect(() => {
    refetch()
  }, [search])

  const handleAdd = () => {
    history.push({
      pathname: ELinks.apartmentForm,
      state: {
        data: undefined,
        mode: 'add'
      }
    })
  }

  const goView = (item: Apartment) => {
    history.push({
      pathname: `${ELinks.apartments}/${item.id}`,
      state: item
    })
  }

  return (
    <Grid
      style={{ width: '100%' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Stack sx={{ width: '90%', gap: 2 }}>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Agregar apartamento
          </Button>

          <RoundedTextField
            fullWidth
            placeholder="Buscar apartamentos"
            value={search}
            onChange={((e) => setSearch(e.target.value))}
          />
          {
            query.isFetching
            && <CircularProgress />
          }
          {
            !query.isFetching
            && (query.data?.data.rows || []).map((item) => (
              <RoundedCard
                key={item.id}
                sx={{
                  width: '100%',
                  flexShrink: 0,
                }} onClick={() => goView(item)}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  padding: '1.3rem'
                }}>
                  <Box sx={{
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>

                    <ApartmentIcon sx={{
                      width: '77px',
                      height: '77px'
                    }} />
                  </Box>
                  <Stack gap={1}>
                    <Title variant="h5">{item.fullAddress}</Title>
                    <TypographyIcon startIcon={Place}>
                      {item.city}
                    </TypographyIcon>
                  </Stack>
                </Box>
              </RoundedCard>
            ))
          }
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Pagination
            count={query.data?.data.metadata.totalPages || 0}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
      </Stack>
    </Grid>
  )
}

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 1.2rem;
  `
