import { Box, Stack, Typography } from '@mui/material'
import { Apartment as ApartmentIcon, LocationCity } from '@mui/icons-material'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { RoundedCard } from '../material-rounded/RoundedCard'
import { TypographyIcon } from '../material-rounded/TypographyIcon'
import { localeDateOptions } from '../../../utils/time.helper'

interface Props {
  fullAddress: string
  startedAt: Date
  endedAt: Date
  cost: number
  onClick?: () => void
  city: string
}

export const ApartmentRentCard = (item: Props) => (
  <RoundedCard onClick={item.onClick} sx={{ width: '100%' }}>
    <Box
      width='100%'
      display='flex'
      padding='1rem'
      justifyContent='space-between'
    >
      <Stack>
        <TypographyIcon
          variant='body2'
          marginLeft='0.25rem'
          startIcon={ApartmentIcon}
        >
          {item.fullAddress}
        </TypographyIcon>
        <TypographyIcon
          variant='body2'
          marginLeft='0.25rem'
          startIcon={LocationCity}
        >
          {item.city}
        </TypographyIcon>
        <TypographyIcon
          variant='body2'
          marginLeft='0.25rem'
          startIcon={EventNoteIcon}
        >
          Desde:{' '}
          {new Date(item.startedAt).toLocaleString('es-AR', localeDateOptions)}
        </TypographyIcon>
        <TypographyIcon
          variant='body2'
          marginLeft='0.25rem'
          startIcon={EventNoteIcon}
        >
          Hasta: {new Date(item.endedAt).toLocaleString('es-AR', localeDateOptions)}
        </TypographyIcon>
      </Stack>
      <Typography variant='h6' paragraph fontWeight='bold'>
        ${item.cost}
      </Typography>
    </Box>
  </RoundedCard>
)
