/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { ArrowBack, Edit } from '@mui/icons-material'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { RQueryKeys, getCarQueryKey } from '../../../../types/react-query'
import { ELinks } from '../../../routes/links'
import CarService from '../../../../services/basics/car.service'
import { getQueryError } from '../../../../utils/error.helper'

export const Car = () => {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()

  const { data } = useQuery(getCarQueryKey(id), async () => {
    const res = await CarService.getOne(id)
    return res.data
  }, { cacheTime: 1000 * 60 * 10 })

  const deleteMutation = useMutation(CarService.deleteOne, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.Car)
      queryClient.invalidateQueries(RQueryKeys.Cars)
    }
  })

  const handleDelete = async () => {
    if (!data) return
    await deleteMutation.mutateAsync(data.id)
    goBack()
  }

  const goEdit = () => {
    history.push({
      pathname: ELinks.carForm,
      state: {
        mode: 'update',
        data
      }
    })
  }
  const goBack = () => history.push(ELinks.cars)
  const goRent = () => history.push({
    pathname: ELinks.carRents,
    state: {
      data
    }
  })
  const goCreateRent = () => {
    history.push({
      pathname: ELinks.carRentForm,
      state: {
        data: {
          car: data,
        },
        mode: 'add'
      }
    })
  }

  return (
    <Grid
      style={{ width: '100%' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Stack
        sx={{
          width: '90%',
          gap: 2,
          padding: 1
        }}
      >
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby="modal-modal-delete"
          aria-describedby="modal-modal-delete-car"
          sx={{
            display: 'flex',
            alignitems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Container
            maxWidth='sm'
          >
            <Box sx={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              borderRadius: '.8rem',
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Borrar Auto
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {`Desea borrar ${data?.make} ${data?.model} ?`}
              </Typography>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                marginY: '1rem',
                gap: '1rem'
              }}>
                <Button
                  variant='outlined'
                  onClick={() => setDeleteModalOpen(false)}
                  disabled={deleteMutation.isLoading}
                >
                  Cancelar
                </Button>
                <Button
                  color='error'
                  variant='contained'
                  onClick={handleDelete}
                  disabled={deleteMutation.isLoading}
                >
                  {
                    deleteMutation.isLoading
                    && <CircularProgress size='1rem' />
                  }
                  Borrar
                </Button>
              </Box>
              {
                deleteMutation.isError
                && <Alert severity='error'>
                  {getQueryError(deleteMutation.error)}
                </Alert>
              }
            </Box>
          </Container>
        </Modal>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '10%',
          justifyContent: 'space-between'
        }}>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <IconButton onClick={goEdit}>
            <Edit />
          </IconButton>
        </Box>

        <RoundedCard sx={{
          padding: '1rem',
          flexShrink: 0
        }}>
          <Typography variant='h6' fontWeight={600}>
            Descripcion
          </Typography>
          <Divider />
          <Stack gap={0.5}>
            <DescriptionRow label='Marca' value={data?.make || ''} />
            <DescriptionRow label='Modelo' value={data?.model || ''} />
            <DescriptionRow label='Color' value={data?.color || ''} />
            <DescriptionRow label='Año' value={`${data?.yearOfManufacturing}` || ''} />
          </Stack>
        </RoundedCard>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '1rem'
        }}>
          <Stack gap={1}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              onClick={goCreateRent}
            >
              Crear alquiler
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={goRent}
            >
              Ver alquileres
            </Button>
          </Stack>
          <Button
            onClick={() => setDeleteModalOpen(true)}
            variant='text'
            size='large'
            color='error'
            sx={{
              width: '70%',
              marginY: '2rem'
            }}>
            ELIMINAR AUTO
          </Button>
        </Box>
      </Stack>
    </Grid>
  )
}

interface DescriptionRowProps {
  label: string
  value: string
}

const DescriptionRow = ({ label, value }: DescriptionRowProps) => (
  <Box sx={{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  }}>
    <Typography>{label}</Typography>
    <Typography>{value}</Typography>
  </Box>
)
