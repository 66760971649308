import React, { useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextFieldProps,
  Typography,
  debounce,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { ArrowBack } from '@mui/icons-material'
import {
  RQueryKeys,
  getCarRentQueryKey,
} from '../../../../types/react-query'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { ELinks } from '../../../routes/links'
import { Car, CarRent } from '../../../../types/types'
import CarRentService from '../../../../services/basics/car-rent.service'
import { CarRentCard } from '../../../shared/Car/CarRentCard'
import CarService from '../../../../services/basics/car.service'

interface DateRange {
  startedAt: Date | null
  endedAt: Date | null
}

interface Location {
  mode: string
  data?: Car
}

export const CarRentsView = () => {
  const history = useHistory()
  const location = useLocation<Location>()
  const car = location.state?.data
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [dateRange, setDateRange] = useState<DateRange>({
    startedAt: null,
    endedAt: null,
  })
  const [selectedCar, setSelectedCar] = useState<Car | null>(
    car || null
  )

  const queryClient = useQueryClient()

  const carRentsQuery = useQuery(
    [RQueryKeys.CarRents, page, selectedCar],
    () => {
      if (selectedCar) {
        return CarRentService.listByCarId(
          selectedCar.id,
          search,
          page,
          dateRange.startedAt,
          dateRange.endedAt
        )
      }
      return CarRentService.list(
        search,
        page,
        dateRange.startedAt,
        dateRange.endedAt
      )
    }
  )

  const carsQuery = useQuery(
    [RQueryKeys.Cars, selectedCar],
    () => CarService.list(search, 1)
  )

  const refetch = useMemo(() => debounce(carRentsQuery.refetch, 500), [])

  useEffect(() => {
    refetch()
  }, [search, dateRange])

  const goView = (item: CarRent) => {
    queryClient.setQueryData(getCarRentQueryKey(item.id), item)
    history.push({
      pathname: `${ELinks.carRent}/${item.id}`,
      state: item,
    })
  }

  const goBack = () => {
    if (car) {
      history.push(`${ELinks.cars}/${car?.id}`)
    } else {
      history.push(ELinks.cars)
    }
  }

  return (
    <Grid
      style={{ width: '100%' }}
      container
      alignItems='center'
      justifyContent='center'
      flexDirection={'column'}
    >
      <Stack sx={{ width: '90%', gap: 2 }}>
        <Box display='flex' alignItems='center'>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant='h5' component='h1'>
            Alquileres de autos
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}
        >
          <RoundedTextField
            fullWidth
            placeholder='Buscar'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Stack gap={2}>
            <Autocomplete
              loading={carsQuery.isLoading}
              options={carsQuery.data?.data.rows || []}
              getOptionLabel={(option) => `${option.make} ${option.model}`}
              isOptionEqualToValue={(option: Car) => option.id === selectedCar?.id
              }
              value={selectedCar}
              onChange={(e, value) => setSelectedCar(value)}
              renderInput={(params: TextFieldProps) => (
                <RoundedTextField
                  {...params}
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  size='small'
                  placeholder='Seleccionar auto'
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction='row' gap={3}>
                <DatePicker
                  onChange={(newValue: Date | null) => {
                    setDateRange((prev) => ({ ...prev, startedAt: newValue }))
                  }}
                  value={dateRange.startedAt}
                  inputFormat='dd/MM/yyyy'
                  label='Desde'
                  renderInput={(params: TextFieldProps) => (
                    <RoundedTextField
                      {...params}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Desde',
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                />
                <DatePicker
                  onChange={(newValue: Date | null) => {
                    setDateRange((prev) => ({ ...prev, endedAt: newValue }))
                  }}
                  value={dateRange.endedAt}
                  inputFormat='dd/MM/yyyy'
                  label='Hasta'
                  renderInput={(params: TextFieldProps) => (
                    <RoundedTextField
                      {...params}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Hasta',
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
          {carRentsQuery.isFetching && <CircularProgress />}
          {!carRentsQuery.isFetching
            && (carRentsQuery.data?.data?.rows || []).map((item) => (
              <CarRentCard
                key={item.id}
                make={item.car?.make}
                model={item.car?.model}
                startedAt={item.startedAt}
                endedAt={item.endedAt}
                cost={item.cost}
                onClick={() => goView(item)}
              />
            ))}
          {carRentsQuery.data?.data?.rows?.length === 0 && (
            <Typography variant='h6'>No se encontraron alquileres</Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            count={carRentsQuery.data?.data.metadata.totalPages || 0}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
      </Stack>
    </Grid>
  )
}
