import {
  IsNotEmpty,
  IsOptional,
  IsString,
  IsNumber,
  IsDateString,
  IsUUID,
  IsInt,
} from 'class-validator'

export class CreateApartmentRentDto {
  @IsNumber()
  @IsInt({ message: 'Ingrese un precio valido' })
  cost: number

  @IsDateString(null, { message: 'La fecha de inicio debe ser una fecha valida' })
  startedAt: string

  @IsDateString(null, { message: 'La fecha de finalizacion debe ser una fecha valida' })
  endedAt: string

  @IsUUID()
  apartmentId: string

  @IsOptional()
  @IsUUID()
  clientId?: string
}

export class UpdateApartmentRentDto {
  @IsString()
  @IsNotEmpty()
  id: string

  @IsOptional()
  @IsNumber()
  @IsInt({ message: 'Ingrese un precio valido' })
  cost: number

  @IsOptional()
  @IsDateString(null, { message: 'La fecha de inicio debe ser una fecha valida' })
  startedAt?: Date

  @IsOptional()
  @IsDateString(null, { message: 'La fecha de finalizacion debe ser una fecha valida' })
  endedAt?: Date

  @IsOptional()
  @IsUUID()
  clientId?: string
}
