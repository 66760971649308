import {
  Alert,
  Button,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { Formik } from 'formik'
import { useMutation } from 'react-query'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { UpdatePasswordDto } from '../../../../validations/basic/auth.dto'
import createValidator from '../../../../utils/class-validator-formik'
import AuthService from '../../../../services/basics/auth.service'

const initialValues: UpdatePasswordDto = {
  currentPassword: '',
  newPassword: '',
  repeatNewPassword: ''
}

export const ProfileView = () => {
  const validate = createValidator(UpdatePasswordDto)

  const updatePasswordMutation = useMutation(AuthService.updatePassword)

  const onSubmit = async (values: UpdatePasswordDto) => {
    await updatePasswordMutation.mutateAsync(values)
  }

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        maxWidth: 'xs',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}>
        {(formik) => (
          <Stack
            width='90%'
            height={'100%'}
            gap={2}
            alignItems={'center'}
          >
            <Typography variant='h5'>Cambiar Contraseña </Typography>

            <RoundedTextField
              autoFocus
              required
              label='Contraseña Actual'
              type='password'
              name='currentPassword'
              placeholder="********"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
              helperText={formik.touched.currentPassword && formik.errors.currentPassword}

            />
            <RoundedTextField
              required
              label='Nueva Contraseña'
              type='password'
              name='newPassword'
              placeholder="********"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}

            />
            <RoundedTextField
              required
              label='Repetir Contraseña'
              type='password'
              placeholder="********"
              name='repeatNewPassword'
              value={formik.values.repeatNewPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
              helperText={formik.touched.repeatNewPassword && formik.errors.repeatNewPassword}
            />

            {
              updatePasswordMutation.isSuccess
              && <Alert severity="success">Contraseña Actualizada!</Alert>
            }  {
              updatePasswordMutation.isError
              && <Alert severity="error">La contraseña actual es incorrecta</Alert>
            }
            <Button
              variant='contained'
              sx={{ width: '60%' }}
              type='submit'
              onClick={() => formik.handleSubmit()}
              disabled={updatePasswordMutation.isLoading}
            >
              Aceptar
            </Button>
          </Stack>
        )}
      </Formik>
    </Grid >
  )
}
