import API, { AxiosConfig } from '../../config/axios.config'
import { Res } from '../../types/response.types'
import {
  Chart,
  RentEntity,
  TopRentedApartment,
  TopRentedCar,
  PopularRentalMonth,
  AverageRentByCity,
  Expense,
  MonthlyExpenseCostAugmentRate,
  ExpenseDescriptionAverageCost
} from '../../types/types'

const getBasepath = (entity: RentEntity): string => `/${entity}-rents-metrics`

function getWeekOfMonth(date: Date): number {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
  const firstDayOfWeek = firstDayOfMonth.getDay()
  const offsetDate = date.getDate() + firstDayOfWeek
  const weekNumber = Math.ceil(offsetDate / 7)
  return weekNumber
}

const getTotalRevenueThisYear = async (entity: RentEntity): Promise<number> => {
  const basepath = getBasepath(entity)
  const today = new Date()
  const year = today.getFullYear()
  const res = await API.get<Res<number>>(`${basepath}/year/${year}`, AxiosConfig())
  return res.data.data
}

const getTotalRevenueThisMonth = async (entity: RentEntity): Promise<number> => {
  const basepath = getBasepath(entity)
  const today = new Date()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const res = await API.get<Res<number>>(
    `${basepath}/month/${year}/${month}`,
    AxiosConfig()
  )
  return res.data.data
}

const getOccupancyRate = async (entity: RentEntity): Promise<number> => {
  const basepath = getBasepath(entity)
  const res = await API.get<Res<number>>(
    `${basepath}/${entity}-occupancy-rate`,
    AxiosConfig()
  )
  return res.data.data
}

const getOccupancyRateThisMonth = async (entity: RentEntity): Promise<number> => {
  const basepath = getBasepath(entity)
  const today = new Date()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const res = await API.get<Res<number>>(
    `${basepath}/${year}/month/${month}/${entity}-occupancy-rate`,
    AxiosConfig()
  )
  return res.data.data
}

const getTotalRentsThisWeek = async (entity: RentEntity): Promise<number> => {
  const basepath = getBasepath(entity)
  const today = new Date()
  const weekNumber = getWeekOfMonth(today)
  const year = today.getFullYear()
  const res = await API.get<Res<number>>(
    `${basepath}/${year}/week/${weekNumber}/rents`,
    AxiosConfig()
  )
  return res.data.data
}

const getTotalRentsThisMonth = async (entity: RentEntity): Promise<number> => {
  const basepath = getBasepath(entity)
  const today = new Date()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const res = await API.get<Res<number>>(
    `${basepath}/${year}/month/${month}/rents`,
    AxiosConfig()
  )
  return res.data.data
}

const getAverageDuration = async (entity: RentEntity): Promise<number> => {
  const basepath = getBasepath(entity)
  const today = new Date()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const res = await API.get<Res<number>>(
    `${basepath}/average-duration-of-rentals/${month}/${year}`,
    AxiosConfig()
  )
  return res.data.data
}

const getTopRented = async (entity: RentEntity): Promise<Chart[]> => {
  const basepath = getBasepath(entity)
  const res = await API.get<Res<any>>(
    `${basepath}/top-rented-${entity}s`,
    AxiosConfig()
  )
  if (entity === RentEntity.Car) {
    return res.data?.data?.map((item: TopRentedCar) => ({
      x: `${item.make} ${item.model}`,
      y: Number(item.count),
    }))
  }
  if (entity === RentEntity.Apartment) {
    return res.data?.data?.map((item: TopRentedApartment) => ({
      x: item.address,
      y: Number(item.count),
    }))
  }
  return res.data.data
}

const getTopRentedThisMonth = async (entity: RentEntity): Promise<Chart[]> => {
  const basepath = getBasepath(entity)
  const today = new Date()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const res = await API.get<any>(
    `${basepath}/top-rented-${entity}s/${month}/${year}`,
    AxiosConfig()
  )
  if (entity === RentEntity.Car) {
    return res.data.data.map((item: TopRentedCar) => ({
      x: `${item.make} ${item.model}`,
      y: Number(item.count),
    }))
  }
  if (entity === RentEntity.Apartment) {
    return res.data.data.map((item: TopRentedApartment) => ({
      x: item.address,
      y: Number(item.count),
    }))
  }
  return res.data.data
}

const getPopularRentalMonths = async (entity: RentEntity): Promise<Chart[]> => {
  const basepath = getBasepath(entity)
  const LIMIT = 12
  const today = new Date()
  const year = today.getFullYear()
  const res = await API.get<Res<PopularRentalMonth[]>>(
    `${basepath}/popular-rental-months/${year}/${LIMIT}`,
    AxiosConfig()
  )
  return res.data.data.map((item: PopularRentalMonth) => {
    const date = new Date()
    date.setMonth(item.month - 1)
    const monthName = date
      .toLocaleString('es-Ar', { month: 'short' })
      .toUpperCase()
    return {
      x: monthName,
      y: item.frequency,
    }
  })
}

const getAverageRentByCity = async (entity: RentEntity): Promise<Chart[] | null> => {
  if (entity === RentEntity.Car) {
    return null
  }
  const basepath = getBasepath(entity)
  const res = await API.get<Res<AverageRentByCity[]>>(
    `${basepath}/average-rent-by-city`,
    AxiosConfig()
  )
  return res.data.data.sort((a: AverageRentByCity, b: AverageRentByCity) => (
    Number(b.averageRent) - Number(a.averageRent)
  )).map((item: AverageRentByCity) => (
    {
      x: item.city,
      y: Number(item.averageRent)
    }
  ))
}

const getAverageExpenseCostByDescription = async (entity: RentEntity): Promise<Chart[] | null> => {
  const basepath = getBasepath(entity)
  const res = await API.get<Res<ExpenseDescriptionAverageCost[]>>(
    `${basepath}/average-expense-cost-by-description`,
    AxiosConfig()
  )
  return res.data.data.sort((a: ExpenseDescriptionAverageCost, b: ExpenseDescriptionAverageCost) => (
    b.averageCost - a.averageCost
  ))
    .map((item: ExpenseDescriptionAverageCost) => (
      {
        x: item.description,
        y: Number(item.averageCost)
      }
    ))
}

const getMostExpensiveExpenses = async (entity: RentEntity): Promise<Chart[] | null> => {
  const LIMIT = 10
  const basepath = getBasepath(entity)
  const res = await API.get<Res<Expense[]>>(
    `${basepath}/most-expensive-expenses/${LIMIT}`,
    AxiosConfig()
  )

  return res.data.data.sort((a: Expense, b: Expense) => (
    b.cost - a.cost
  ))
    .map((item: Expense) => (
      {
        x: item.description,
        y: Number(item.cost)
      }
    ))
}

const getExpenseCostAugmentRate = async (entity: RentEntity): Promise<Chart[] | null> => {
  const basepath = getBasepath(entity)
  const res = await API.get<Res<MonthlyExpenseCostAugmentRate[]>>(
    `${basepath}/expense-cost-augment-rate`,
    AxiosConfig()
  )
  return res.data.data.map((item: any) => {
    const date = new Date()
    date.setMonth(item.month - 1)
    const monthName = date
      .toLocaleString('es-Ar', { month: 'short' })
      .toUpperCase()
    return {
      x: `${monthName} ${item.year}`,
      y: Number(item.augmentRate),
      tooltip: `
        ${item.augmentRate}% | 
        ${item.expenseDescription} | ${entity === RentEntity.Apartment ? item.apartmentAddress : item.carModel}
      `
    }
  })
}

const MetricsService = {
  getTotalRevenueThisYear,
  getTotalRevenueThisMonth,
  getOccupancyRate,
  getOccupancyRateThisMonth,
  getTotalRentsThisWeek,
  getTotalRentsThisMonth,
  getAverageDuration,
  getTopRented,
  getTopRentedThisMonth,
  getPopularRentalMonths,
  getAverageRentByCity,
  getAverageExpenseCostByDescription,
  getMostExpensiveExpenses,
  getExpenseCostAugmentRate
}

export default MetricsService
