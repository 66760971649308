import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { CreateClientDto, UpdateClientDto } from '../../../../../validations/client.dto'
import { AgencyCar } from '../../../../../types/types'
import createValidator from '../../../../../utils/class-validator-formik'
import AddClientService, { } from '../../../../../services/client.service'
import { RQueryKeys, getAgencyCarQueryKey } from '../../../../../types/react-query'
import { RoundedTextField } from '../../../../shared/material-rounded/RoundedTextField'
import { getFormikProps } from '../../../../../utils/formik.helper'
import { RoundedSelect } from '../../../../shared/material-rounded/RoundedSelect'
import { ELinks } from '../../../../routes/links'

type Values = CreateClientDto
const createInitialValues: CreateClientDto = {
  fullName: '',
  fullAddress: '',
  phoneNumber: '',
  email: '',
  gender: 'male',
}

export interface AgencyCarFormProps {
  mode: 'add' | 'update'
  data: AgencyCar
}

export const AddClient = () => {
  const history = useHistory()
  const location = useLocation<AgencyCarFormProps>()
  const { mode = 'add', data } = location.state || {}
  const queryClient = useQueryClient()
  const initialValues = mode === 'update' ? data : createInitialValues
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formValues, setFormValues] = useState<Values>(initialValues as Values)

  const createMutation = useMutation(AddClientService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.AgencyCar)
    }
  })
  const updateMutation = useMutation(AddClientService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(RQueryKeys.AgencyCar)
      queryClient.invalidateQueries(getAgencyCarQueryKey(data.id))
    }
  })

  const validate = mode === 'add'
    ? createValidator(CreateClientDto)
    : createValidator(UpdateClientDto)

  const onSubmit = async (values: Values) => {
    try {
      if (mode === 'add') {
        await createMutation.mutateAsync(values as CreateClientDto)
      } else if (mode === 'update') {
        await updateMutation.mutateAsync(values as UpdateClientDto)
      }

      history.push(ELinks.clients)
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }
  const isLoading = createMutation.isLoading || updateMutation.isLoading
  return (
    <Grid
      style={{ height: '100vh' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      {
        isLoading
        && <Box sx={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 10
        }}>
          <CircularProgress />
          <Typography>Guardando...</Typography>
        </Box>
      }
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Stack
            width='90%'
            height={'100%'}
            gap={2}
            alignItems={'center'}
          >

            <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>Agregar Cliente</Typography>
            </Box>
            <RoundedTextField
              required
              label='Nombre Completo'
              {...getFormikProps(formik, 'fullName')}
            />
            <RoundedTextField
              required
              label='Direccion'
              {...getFormikProps(formik, 'fullAddress')}
              error={formik.touched.fullAddress && Boolean(formik.errors.fullAddress)}
              helperText={
                formik.touched.fullAddress && formik.errors.fullAddress
                  ? formik.errors.fullAddress
                  : undefined
              }
            />
            <RoundedTextField
              required
              label='Email'
              {...getFormikProps(formik, 'email')}
            />
            <RoundedTextField
              required
              label='Celular'
              {...getFormikProps(formik, 'phoneNumber')}
            />
            <RoundedSelect
              label='Sexo'
              sx={{
                width: '100%'
              }}
              name='gender'
              onChange={(event) => {
                formik.handleChange(event)
              }}
              value={`${formik.values.gender}`}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
            >
              <MenuItem value={'female'}>Femenino</MenuItem>
              <MenuItem value={'male'}>Masculino</MenuItem>
            </RoundedSelect>
            <Button
              variant='contained'
              sx={{ width: '40%' }}
              size='large'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            >
              {
                isLoading
                && <CircularProgress />
              }
              Guardar
            </Button>
          </Stack>
        )}
      </Formik>
    </Grid >
  )
}
