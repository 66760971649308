import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Formik, FormikHelpers } from 'formik'
import { ArrowBack } from '@mui/icons-material'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { getFormikProps } from '../../../../utils/formik.helper'
import { CreateApartmentDto, UpdateApartmentDto } from '../../../../validations/apartment.dto'
import { Apartment } from '../../../../types/types'
import ApartmentService from '../../../../services/basics/apartment.service'
import { ELinks } from '../../../routes/links'

interface Location {
  mode: string,
  data?: Apartment
}

type Values = CreateApartmentDto | UpdateApartmentDto

const createInitialValues: CreateApartmentDto = {
  fullAddress: '',
  city: '',
}

export const ApartmentForm = () => {
  const history = useHistory()
  const location = useLocation<Location>()
  const { data, mode = 'add' } = location.state

  const [title, setTitle] = useState('Agregar apartamento')
  const [initialValues, setInitialValues] = useState<Values>(createInitialValues)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (mode === 'add') setTitle('Agregar apartamento')
    else if (mode === 'update') setTitle('Editar apartamento')

    if (mode === 'update' && data) {
      setInitialValues({
        ...data
      })
    }
  }, [mode, data])

  const onSuccess = () => {
    queryClient.invalidateQueries(RQueryKeys.Apartments)
    queryClient.invalidateQueries(RQueryKeys.Apartment)
  }
  const createMutation = useMutation(ApartmentService.create, { onSuccess })
  const updateMutation = useMutation(ApartmentService.update, { onSuccess })

  const onSubmit = async (values: Values, formik: FormikHelpers<Values>) => {
    if (mode === 'add') {
      await createMutation.mutateAsync(values as CreateApartmentDto)
    } else {
      await updateMutation.mutateAsync(values as UpdateApartmentDto)
    }
    formik.resetForm()
    history.replace(ELinks.apartments)
  }

  const validate = mode === 'add'
    ? createValidator(CreateApartmentDto)
    : createValidator(UpdateApartmentDto)

  const isLoading = createMutation.isLoading || updateMutation.isLoading

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Stack
            width='90%'
            height={'100%'}
            gap={2}
            alignItems={'center'}
          >

            <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>{title}</Typography>
            </Box>

            <Stack
              width='100%'
              gap={2}
              alignItems={'center'}
              padding='0.5rem'
            >
              <RoundedTextField
                required
                label='Direccion'
                {...getFormikProps(formik, 'fullAddress')}
              />
              <RoundedTextField
                required
                label='Ciudad'
                {...getFormikProps(formik, 'city')}
              />

            </Stack>
            <Button
              variant='contained'
              sx={{ width: '40%' }}
              size='large'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            >
              {
                isLoading
                && <CircularProgress />
              }
              Guardar
            </Button>
          </Stack>
        )}
      </Formik>
    </Grid>
  )
}
