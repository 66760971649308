import {
  Add,
} from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { list } from '../../../../services/client.service'
import { RoundedTextField } from '../../../shared/material-rounded/RoundedTextField'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { TypographyIcon } from '../../../shared/material-rounded/TypographyIcon'
import { ELinks } from '../../../routes/links'
import { RQueryKeys, getClientQueryKey } from '../../../../types/react-query'
import { Client } from '../../../../types/types'
import { Paginated, Res } from '../../../../types/response.types'

export const ClientsView = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()

  const addClientsQueryKey = useQuery<Res<Paginated<Client>>, Error>(
    [RQueryKeys.Clients, page],
    async () => {
      const res = await list(search, page)
      return res
    }
  )

  const viewClient = (clients: Client) => {
    queryClient.setQueryData(getClientQueryKey(clients.id), clients)
    history.push({
      pathname: `${ELinks.client}/${clients.id}`,
      state: clients,
    })
  }

  const debounceSearchRefetch = useMemo(() => debounce(addClientsQueryKey.refetch, 500), [])

  useEffect(() => {
    debounceSearchRefetch()
  }, [search])

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      maxWidth='xs'
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Stack
        width='90%'
        height={'100%'}
        gap={2}
        alignItems={'center'}
      >
        <Button
          startIcon={<Add />}
          variant='contained'
          sx={{ width: '60%' }}
          onClick={() => history.push(ELinks.clientForm)}
        >
          Agregar Cliente
        </Button>
        <RoundedTextField
          label='Buscar'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {
          (addClientsQueryKey.data?.data.rows || []).map((clients) => (
            <RoundedCard
              key={clients.id}
              sx={{
                width: '100%',
                flexShrink: 0,
              }}
              onClick={() => viewClient(clients)}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '1.3rem'
              }}>
                <Stack gap={1}>
                  <TypographyIcon startIcon={PersonIcon}>{clients.fullName}</TypographyIcon>
                  <TypographyIcon startIcon={HomeIcon}>{clients.fullAddress}</TypographyIcon>
                </Stack>
              </Box>
            </RoundedCard>
          ))
        }

        {
          addClientsQueryKey.data
          && <Pagination
            count={addClientsQueryKey.data?.data.metadata.totalPages}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        }
      </Stack>
    </Grid >

  )
}
