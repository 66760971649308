import React, { useState } from 'react'
import {
  Grid,
  Tab,
  Tabs,
  Stack,
  Typography,
  Box,
  Divider,
  styled,
} from '@mui/material'

import { useQuery } from 'react-query'
import { RoundedCard } from '../../../shared/material-rounded/RoundedCard'
import { BarChart } from './BarChart'
import MetricsService from '../../../../services/basics/metrics.service'
import { RentEntity } from '../../../../types/types'
import { RQueryKeys } from '../../../../types/react-query'
import { LineChart } from './LineChart'

interface Graph {
  label: string
  data: any
  type: 'bar' | 'line'
  formatAsPrice?: boolean
  withTooltip?: boolean
}

export const DashboardView = () => {
  const [value, setValue] = useState(0)
  const [entity, setEntity] = useState<RentEntity>(RentEntity.Apartment)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    setEntity(newValue === 0 ? RentEntity.Apartment : RentEntity.Car)
  }
  const totalRevenueThisYearQuery = useQuery(
    [RQueryKeys.TotalRevenueThisYear, value],
    () => MetricsService.getTotalRevenueThisYear(entity)
  )
  const totalRevenueThisMonthQuery = useQuery(
    [RQueryKeys.TotalRevenueThisMonth, value],
    () => MetricsService.getTotalRevenueThisMonth(entity)
  )
  const occupancyRateQuery = useQuery(
    [RQueryKeys.OccupancyRate, value],
    () => MetricsService.getOccupancyRate(entity)
  )
  const occupancyRateThisMonthQuery = useQuery(
    [RQueryKeys.OccupancyRateThisMonth, value],
    () => MetricsService.getOccupancyRateThisMonth(entity)
  )
  const totalRentsThisMonthQuery = useQuery(
    [RQueryKeys.TotalRentsThisMonth, value],
    () => MetricsService.getTotalRentsThisMonth(entity)
  )
  const totalRentsThisWeekQuery = useQuery(
    [RQueryKeys.TotalRentsThisWeek, value],
    () => MetricsService.getTotalRentsThisWeek(entity)
  )
  const averageDurationQuery = useQuery(
    [RQueryKeys.AverageDuration, value],
    () => MetricsService.getAverageDuration(entity)
  )
  const topRentedQuery = useQuery(
    [RQueryKeys.TopRented, value],
    () => MetricsService.getTopRented(entity)
  )
  const topRentedThisMonthQuery = useQuery(
    [RQueryKeys.TopRentedThisMonth, value],
    () => MetricsService.getTopRentedThisMonth(entity)
  )
  const popularRentalMonthsQuery = useQuery(
    [RQueryKeys.PopularRentalMonths, value],
    () => MetricsService.getPopularRentalMonths(entity)
  )
  const averageRentByCityQuery = useQuery(
    [RQueryKeys.AverageRentByCity, value],
    () => MetricsService.getAverageRentByCity(entity)
  )

  const graphs: Graph[] = [
    {
      label: `Más alquilados en ${new Date().toLocaleString('es-Ar', {
        month: 'long',
      })}`,
      data: topRentedThisMonthQuery.data,
      type: 'bar',
    },
    {
      label: 'Más alquilados',
      data: topRentedQuery.data,
      type: 'bar'
    },
    {
      label: 'Alquileres por mes',
      data: popularRentalMonthsQuery.data,
      type: 'bar'
    },
    {
      label: 'Promedio de alquiler por ciudad',
      data: averageRentByCityQuery.data,
      type: 'bar',
      formatAsPrice: true,
      withTooltip: true
    },
  ]
  return (
    <Box alignItems='center' display='flex' flexDirection='column'>
      <Tabs value={value} onChange={handleChange}>
        <Tab label='Apartamentos' />
        <Tab label='Autos' />
      </Tabs>
      <Stack mt='1rem' width='100%' gap='1rem'>
        <RoundedCard sx={{ width: '100%', padding: '1rem' }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            width='100%'
            alignItems='center'
            mb='1rem'
          >
            <StatBox>
              <Typography variant='h6' fontWeight='bold'>
                ${totalRevenueThisYearQuery?.data || 0}
              </Typography>
              <Typography fontSize='0.75rem' textAlign='center'>
                Este año
              </Typography>
            </StatBox>
            <Divider orientation='vertical' flexItem />
            <StatBox>
              <Typography variant='h6' fontWeight='bold'>
                {occupancyRateQuery?.data || 0}%
              </Typography>
              <Typography fontSize='0.75rem' variant='body2' textAlign='center'>
                Ocupación promedio
              </Typography>
            </StatBox>
            <Divider orientation='vertical' flexItem />
            <StatBox>
              <Typography variant='h6' fontWeight='bold'>
                {totalRentsThisMonthQuery?.data || 0}
              </Typography>
              <Typography fontSize='0.75rem' textAlign='center'>
                Alquileres este mes
              </Typography>
            </StatBox>
            <Divider orientation='vertical' flexItem />
            <StatBox>
              <Typography textAlign='center' variant='h6' fontWeight='bold'>
                {averageDurationQuery?.data || 0} días
              </Typography>
              <Typography fontSize='0.75rem' textAlign='center'>
                Promedio de alquiler
              </Typography>
            </StatBox>
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            width='100%'
            alignItems='center'
          >
            <StatBox minWidth='33%'>
              <Typography variant='h6' fontWeight='bold'>
                ${totalRevenueThisMonthQuery?.data || 0}
              </Typography>
              <Typography fontSize='0.75rem' textAlign='center'>
                Este mes
              </Typography>
            </StatBox>
            <Divider orientation='vertical' flexItem />
            <StatBox minWidth='33%'>
              <Typography variant='h6' fontWeight='bold'>
                {occupancyRateThisMonthQuery?.data || 0}%
              </Typography>
              <Typography fontSize='0.75rem' variant='body2' textAlign='center'>
                Ocupación este mes
              </Typography>
            </StatBox>
            <Divider orientation='vertical' flexItem />
            <StatBox minWidth='33%'>
              <Typography variant='h6' fontWeight='bold'>
                {totalRentsThisWeekQuery?.data || 0}
              </Typography>
              <Typography fontSize='0.75rem' textAlign='center'>
                Alquileres esta semana
              </Typography>
            </StatBox>
          </Stack>
        </RoundedCard>
        <Grid justifyContent={{ xs: 'center', lg: 'space-around' }} container columns={2}>
          {
            graphs.map((graph) => (
              graph.data && graph.data.length > 0
              && <Grid key={graph.label} item>
                  {graph.type === 'bar'
                    ? <BarChart
                      label={graph.label}
                      data={graph.data}
                      formatAsPrice={graph.formatAsPrice}
                      withTooltip={graph.withTooltip}
                    />
                    : <LineChart
                      label={graph.label}
                      data={graph.data}
                    />}
              </Grid>
            ))
          }
        </Grid>

      </Stack>
    </Box >
  )
}
type StatBoxProps = {
  minWidth?: string
}
const StatBox = styled(Box)<StatBoxProps>(({ minWidth }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  minWidth: minWidth || '25%',
}))
